// noinspection DuplicatedCode,JSUnusedGlobalSymbols

import { z } from 'zod';
import { LOAN_AMOUNT_FOR_FINANCIAL_STATEMENT } from './constants';
import { emailValidator } from './validators/emailValidator';

export const PHONE_NUMBER_REGEX =
  /^(\+?1-?)?\s?([(]?)([2-9][0-8][0-9])([)]?)\s?-?([2-9][0-9]{2})-?([0-9]{4})$/;

// this is here so it can be copied into the function project
export function dateOfBirthValidator(
  value: string | null | undefined,
  input: 'iso' | 'locale' = 'locale'
): void {
  let birthDate;
  if (!value) return; // required rule will handle this
  if (input === 'iso') {
    birthDate = new Date(value);
  } else if (input === 'locale') {
    // looks like m/d/y
    const parts = value.split('/');
    if (parts[2]?.length !== 4) throw new Error('invalid date format');
    birthDate = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[0], 10) - 1,
      parseInt(parts[1], 10)
    );
  } else {
    throw new Error('invalid date format');
  }

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  if (Number.isNaN(age)) throw new Error('Please enter a valid date');
  if (age < 18) throw new Error('You must be at least 18');
  if (age > 100) throw new Error('Please enter a valid date of birth');
}

export function unitNumberValidator(value: string | null | undefined): void {
  if (!value) return;
  const regex = new RegExp(/^[a-zA-Z0-9- ]+$/);
  if (!regex.test(value)) {
    throw new Error('Please enter alphanumeric characters only (20 max)');
  }
}

export enum ResidenceOwnershipStatus {
  Rent = 'rent',
  OwnMortgage = 'ownMortgage',
  Own = 'own',
  Friend = 'friend',
  Military = 'military',
}

const emailSchema = z.string().email().refine(emailValidator, 'Invalid email');

const residenceSchema = z.union([
  z.literal('rent'),
  z.literal('ownMortgage'),
  z.literal('own'),
  z.literal('friend'),
  z.literal('military'),
]);

export const addressSchema = z.object({
  address1: z.string(),
  unitNumber: z
    .string()
    .max(20)
    .superRefine((val, ctx) => {
      try {
        unitNumberValidator(val);
      } catch (e: any) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: e.message,
        });
      }
    })
    .optional()
    .nullable(),
  city: z.string().nullable(),
  county: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  zip: z.string().nullable(),
  yearsAtResidence: z.number().optional().nullable(),
  monthsAtResidence: z.number().optional().nullable(),
  rentOrOwn: residenceSchema.optional().nullable(),
  mortgageRentPayment: z.number().optional().nullable(),
});

// Based upon https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html (North American Numbering Plan)
export const phoneSchema = z
  .string()
  .regex(PHONE_NUMBER_REGEX, {
    message: 'Phone number is not valid',
  })
  .optional();

export const employerSchema = z.object({
  name: z.string().nullable(),
  position: z.string().optional(),
  years: z.number().nullable(),
  months: z.number().optional().nullable().default(0),
  typeOfBusiness: z.string().optional(),
  phone: phoneSchema,
  address: addressSchema.optional().nullable(),
});

export enum RVType {
  classA = 'Class A Motorhome',
  classB = 'Class B Motorhome',
  classC = 'Class C Motorhome',
  superC = 'Class Super C Motorhome',
  classBCustom = 'Class B Custom Motorhome (Out/Up Fitted)',
  adventureOverland = 'Adventure / Overland Motorhome',
  fifthWheel = 'Fifth Wheel Trailer',
  travelTrailer = 'Travel Trailer',
  teardropTrailer = 'Teardrop Trailer',
  campingTrailer = 'Camping Trailer',
  horseTrailerLiving = 'Horse Trailer with Living Quarters',
  horseTrailer = 'Horse Trailer',
  truckCamper = 'Truck Camper',
  iceHouseTrailer = 'Ice House Trailer',
  destinationTrailer = 'Destination Trailer',
}

const RVS_WITH_ENGINES: string[] = [
  RVType.classA,
  RVType.classB,
  RVType.classC,
  RVType.superC,
  RVType.classBCustom,
  RVType.adventureOverland,
];

const TRIDENT_CAMPING_TRAILERS = new Set([RVType.campingTrailer, RVType.teardropTrailer]);

const TRIDENT_TRAVEL_TRAILERS = new Set([
  RVType.destinationTrailer,
  RVType.fifthWheel,
  RVType.iceHouseTrailer,
  RVType.travelTrailer,
]);

enum NADACategory {
  CampingTrailers = 'Camping Trailers',
  MotorHomes = 'Motor Homes',
  TravelTrailers = 'Travel Trailers',
  TruckCampers = 'Truck Campers',
}

export const rvTypeSchema = z.nativeEnum(RVType);

// Return NADA's category based upon our category name
export function mapNADACategory(category: string | undefined | null): string | undefined {
  let result;

  if (category) {
    if (category.includes('Motorhome')) {
      result = NADACategory.MotorHomes;
    } else if (TRIDENT_CAMPING_TRAILERS.has(category as RVType)) {
      result = NADACategory.CampingTrailers;
    } else if (
      category.startsWith(RVType.horseTrailer) ||
      TRIDENT_TRAVEL_TRAILERS.has(category as RVType)
    ) {
      result = NADACategory.TravelTrailers;
    } else if (category === RVType.truckCamper) {
      result = NADACategory.TruckCampers;
    } else {
      result = category;
    }
  }

  return result;
}

export enum BoatType {
  sail = 'Sail',
  power = 'Power',
}

export const boatTypeSchema = z.nativeEnum(BoatType).optional().nullable();

export enum AirplaneType {
  helicopter = 'helicopter',
  jet = 'jet',
  lightSport = 'Light Sport',
  multiProp = 'Multi Engine Prop',
  singleProp = 'Single Prop',
  turboProp = 'Turbo Prop',
}

export const airplaneTypeSchema = z.nativeEnum(AirplaneType);

export function collateralHasRVEngine(
  type: RVType | BoatType | AirplaneType | undefined | null
): boolean {
  let hasRVEngine = false;
  if (type) {
    hasRVEngine = RVS_WITH_ENGINES.includes(type);
  }
  return hasRVEngine;
}

export enum EngineCount {
  single = 'single',
  twin = 'twin',
  triple = 'triple',
  quad = 'quad',
}

export const engineCountSchema = z.nativeEnum(EngineCount);

export const EngineCountMap = new Map<EngineCount, number>([
  [EngineCount.single, 1],
  [EngineCount.twin, 2],
  [EngineCount.triple, 3],
  [EngineCount.quad, 4],
]);

export function getEngineCountEntry(n: number | undefined | null): EngineCount | undefined {
  if ((n ?? 0) > EngineCountMap.size || (n ?? 0) <= 0) {
    return undefined;
  }

  const entriesArray = EngineCountMap.entries();
  for (let i = 0; i <= EngineCountMap.size; i++) {
    const entry = entriesArray.next().value;
    if (entry[1] === n) {
      return entry[0];
    }
  }
  return undefined;
}

export enum ApplicantType {
  individual = 'individual',
  joint = 'joint',
}

export const applicantTypeSchema = z.nativeEnum(ApplicantType);

export enum OwnershipType {
  none = 'Personally',
  llc = 'LLC',
  montanaLlc = 'Montana LLC',
  trust = 'Trust',
}

export const ownershipTypeSchema = z.nativeEnum(OwnershipType);

export enum RelationshipType {
  spouse = 'Spouse',
  significantOther = 'Significant Other',
  domesticPartner = 'Domestic Partner',
  friend = 'Friend',
  parent = 'Parent',
  child = 'Child',
  sibling = 'Sibling',
  grandparent = 'Grandparent',
  cousin = 'Cousin',
  other = 'Other',
}

export const relationshipTypeSchema = z.nativeEnum(RelationshipType);

export enum TrailerAxles {
  single = 'single',
  dual = 'dual',
  triple = 'triple',
}

export const trailerAxlesSchema = z.nativeEnum(TrailerAxles);

export const collateralSchema = z.object({
  manufacturer: z.string().optional().nullable(),
  year: z.number().optional().nullable(),
  model: z.string().optional().nullable(),
  modelNumber: z.string().optional().nullable(),
  modelNADAId: z.number().optional().nullable(),
  mileage: z.number().min(0).optional().nullable(),
  loa: z.number().min(1, { message: 'Please enter a valid length' }).optional().nullable(),
  type: z.union([rvTypeSchema, boatTypeSchema, airplaneTypeSchema]),
  engineManufacturer: z.string().optional().nullable(),
  horsepower: z
    .number()
    .min(0, { message: 'Please enter a valid horsepower' })
    .optional()
    .nullable(),
  engineCount: engineCountSchema.optional().nullable(),
  fuel: z
    .union([z.literal('gas'), z.literal('diesel'), z.literal('electric')])
    .optional()
    .nullable(),
  axles: z.number().optional().nullable(),
  width: z.number().optional().nullable(),
  weight: z.number().optional().nullable(),
  slides: z.number().optional().nullable(),
  transmission: z.number().optional().nullable(),
  boatingExperience: z.string().optional().nullable(),
  trailer: z.boolean().optional().nullable(),
  trailerAxles: trailerAxlesSchema.optional().nullable(), // boat trailer axles
  msrpValue: z.number().optional().nullable(),
  wholesaleValue: z.number().optional().nullable(),
  usedRetailValue: z.number().optional().nullable(),
  specificCollateralKnown: z.boolean().optional().nullable(),
  imtId: z.number().optional().nullable(),
});

export enum LoanPurpose {
  rv = 'RV',
  boat = 'Boat',
  aircraft = 'Aircraft',
}

export const loanPurposeSchema = z.nativeEnum(LoanPurpose);

export enum LoanType {
  new = 'new',
  used = 'used',
  refinance = 'refinance',
  cashRecapture = 'cashRecapture',
}

export const loanTypeSchema = z.nativeEnum(LoanType);

export enum SellerType {
  dealer = 'dealer',
  private = 'private',
  undecided = 'undecided',
}

export const sellerTypeSchema = z.nativeEnum(SellerType);

export enum PrequalApprovalStatus {
  approved = 'Approved',
  notApproved = 'Not Approved',
  pending = 'Pending',
}

export const prequalApprovalStatusSchema = z.nativeEnum(PrequalApprovalStatus);

export enum FullApplicationStatus {
  notStarted = 'Not Started',
  started = 'Started',
  submitted = 'Submitted',
}

export const fullApplicationStatusSchema = z.nativeEnum(FullApplicationStatus);

export enum PrequalApplicationStatus {
  notStarted = 'Not Started',
  started = 'Started',
  submitted = 'Submitted',
}

export const prequalApplicationStatusSchema = z.nativeEnum(PrequalApplicationStatus);

export const financeDetailsSchema = z.object({
  purchasePrice: z.number().min(0).nullable(),
  salesTax: z.number().min(0).optional().nullable(),
  downPayment: z.number().min(0).optional().nullable(),
  tradeIn: z.number().min(0).optional().nullable(),
  tradeInOwed: z.number().min(0).optional().nullable(),
  financedAmount: z.number().min(0).nullable(),
  term: z.number().min(0),
  currentLender: z.string().optional().nullable(),
  currentRate: z.number().min(0).optional().nullable(),
  dateOfPurchase: z.string().optional(),
  cashRecaptureAmount: z.number().min(0).optional(),
});

export enum AddOnService {
  extendedServicePlan = 'extendedServicePlan',
  roadHazard = 'roadHazard',
  roadsideAssistance = 'roadsideAssistance',
  gapInsurance = 'gapInsurance',
}

export const addOnServiceSchema = z.nativeEnum(AddOnService);

export enum EmploymentStatus {
  employed = 'Employed',
  selfEmployed = 'Self Employed',
  retired = 'Retired',
  unemployed = 'Unemployed',
}

export const employmentStatusSchema = z.nativeEnum(EmploymentStatus);

export enum IncomeSource {
  salary = 'Salary',
  intDiv = 'Interest and/or Dividends',
  distributions = 'Distributions',
  pension = 'Pension',
  rental = 'Rental',
  other = 'Other',
}

export const incomeSourceSchema = z.nativeEnum(IncomeSource);

export enum AssetName {
  checking = 'Checking',
  savings = 'Savings',
  moneyMarket = 'Money Market',
  otherSavings = 'Other Savings',
  retirement = 'IRA/401k',
  stocks = 'Stocks/Bonds',
  mutualFunds = 'Mutual Funds',
  lifeInsurance = 'Life Insurance',
  other = 'Other',
}

export const assetNameSchema = z.nativeEnum(AssetName);

export const assetSchema = z.object({
  name: assetNameSchema,
  value: z.number().min(0),
});

export enum OtherAssetName {
  auto = 'Auto',
  rv = 'RV',
  marine = 'Marine',
  other = 'Other',
}

export const otherAssetNameSchema = z.nativeEnum(OtherAssetName);

export const otherAssetSchema = z.object({
  name: otherAssetNameSchema,
  percentOwned: z.number().max(100).min(1),
  value: z.number().min(0),
  balance: z.number().min(0).optional().nullable(),
  payment: z.number().min(0).optional().nullable(),
});

export enum LiabilityName {
  creditCard = 'Credit Card',
  personalLoan = 'Personal Loan',
  other = 'Other',
}

export const liabilityNameSchema = z.nativeEnum(LiabilityName);

export const liabilitySchema = z.object({
  name: liabilityNameSchema,
  institution: z.string(),
  balance: z.number().min(0),
  payment: z.number().min(0),
});

export enum RealEstateName {
  residence = 'Residence',
  rental = 'Rental',
  commercial = 'Commercial',
  vacationHome = 'Vacation Home',
  secondHome = 'Second Home',
}

export const realEstateNameSchema = z.nativeEnum(RealEstateName);

export const realEstateSchema = z.object({
  name: realEstateNameSchema,
  value: z.number().min(0),
  balance: z.number().min(0).optional().nullable(),
  payment: z.number().min(0).optional().nullable(),
  balance2: z.number().min(0).optional().nullable(),
  payment2: z.number().min(0).optional().nullable(),
});

export const financialStatementSchema = z.object({
  assets: z.array(assetSchema).optional().nullable(),
  otherAssets: z.array(otherAssetSchema).optional().nullable(),
  liabilities: z.array(liabilitySchema).optional().nullable(),
  realEstate: z.array(realEstateSchema).optional().nullable(),
  maintenancePayments: z.boolean().optional().nullable(),
  defendant: z.boolean().optional().nullable(),
  bankruptcy: z.boolean().optional().nullable(),
});

export const borrowerSchema = z.object({
  firstName: z.string().nullable(),
  middleInitial: z.string().max(1).optional().nullable(),
  lastName: z.string().nullable(),
  dateOfBirth: z
    .string()
    .superRefine((val, ctx) => {
      try {
        dateOfBirthValidator(val);
      } catch (e: any) {
        ctx.addIssue({
          code: 'invalid_date',
          message: e.message,
        });
      }
    })
    .nullable(),
  ssn: z.string().min(11).max(11).nullable(),
  citizen: z.string().nullable(),
  email: emailSchema.nullable(),
  homePhone: phoneSchema.optional().nullable(),
  cellPhone: phoneSchema.optional().nullable(),
  businessPhone: phoneSchema.optional().nullable(),
  currentAddress: addressSchema.optional().nullable(),
  previousAddress: addressSchema.optional().nullable(),
  mailingAddress: addressSchema.optional().nullable(),
  currentEmployer: employerSchema.optional().nullable(),
  currentEmployer2: employerSchema.optional().nullable(),
  previousEmployer: employerSchema.optional().nullable(),
  relationship: relationshipTypeSchema.optional().nullable(),
  hasMailingAddress: z.boolean().default(false), // default as false for co-borrower only
  employmentStatus: employmentStatusSchema.nullable(),
  retiredYears: z.number().optional().nullable(),
  primaryIncomeSource: incomeSourceSchema.nullable(),
  primaryIncomeSourceCustom: z.string().optional().nullable(),
  primaryIncomeAmount: z.number().nullable(),
  secondaryIncomeSource: incomeSourceSchema.optional().nullable(),
  secondaryIncomeSourceCustom: z.string().optional().nullable(),
  secondaryIncomeAmount: z.number().optional().nullable(),
  otherIncomeSource: incomeSourceSchema.optional().nullable(),
  otherIncomeSourceCustom: z.string().optional().nullable(),
  otherIncomeAmount: z.number().optional().nullable(),
  other2IncomeSource: incomeSourceSchema.optional().nullable(),
  other2IncomeSourceCustom: z.string().optional().nullable(),
  other2IncomeAmount: z.number().optional().nullable(),
  other3IncomeSource: incomeSourceSchema.optional().nullable(),
  other3IncomeSourceCustom: z.string().optional().nullable(),
  other3IncomeAmount: z.number().optional().nullable(),
  financialStatement: financialStatementSchema.optional().nullable(),
  sharesAddress: z.boolean().optional().nullable(),
  disclaimerAccepted: z
    .boolean()
    .refine((v) => v, 'Please accept the disclaimer')
    .optional(),
  signature: z.string().optional(),
  signatureDate: z.string().optional(),
  otherCount: z.number().optional().nullable(),
});

// capturing values at the time of submit
export const trackingSchema = z.object({
  ipAddress: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  region: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  cityLatLong: z.string().optional().nullable(),
  deviceType: z.string().optional().nullable(),
  userAgent: z.string().optional().nullable(),
  timeToSubmit: z.string().optional().nullable(),
});

const loanMarketingSchema = z.object({
  adGroup: z.string().optional().nullable(),
  adGroupId: z.number().optional().nullable(),
  campaignId: z.number().optional().nullable(),
  campaignName: z.string().optional().nullable(),
});

const loanUtmSchema = z.object({
  utm_campaign: z.string().optional().nullable(),
  utm_content: z.string().optional().nullable(),
  utm_id: z.string().optional().nullable(),
  utm_medium: z.string().optional().nullable(),
  utm_name: z.string().optional().nullable(),
  utm_source: z.string().optional().nullable(),
  utm_term: z.string().optional().nullable(),
});

const prequalUtmSchema = z.object({
  utmCampaign: z.string().optional().nullable(),
  utmContent: z.string().optional().nullable(),
  utmId: z.string().optional().nullable(),
  utmMedium: z.string().optional().nullable(),
  utmName: z.string().optional().nullable(),
  utmSource: z.string().optional().nullable(),
  utmTerm: z.string().optional().nullable(),
});

export enum LeadOrigin {
  prequal = 'Prequal',
  none = 'None',
}

export const leadOriginSchema = z.nativeEnum(LeadOrigin);

export enum ShoppingSegmentType {
  'Within 3 - 6 months' = "I'm thinking about buying a boat",
  'Within 3 months' = "I'm making an offer on a boat",
  'Right Away' = "My offer is accepted. I'm ready to finance!",
}

export const shoppingSegmentSchema = z.nativeEnum(ShoppingSegmentType);

export enum CreditScoreRangeType {
  '800-850' = 'Exceptional (800+)',
  '760-799' = 'Excellent (760-799)',
  '740-759' = 'Very Good (740-759)',
  '700-739' = 'Good (680-739)',
  '640-679' = 'Fair (640-679)',
  '600-639' = 'Poor (below 640)',
}

export const creditScoreSchema = z.nativeEnum(CreditScoreRangeType);

export const prequalSchema = z.object({
  imtId: z.string().optional().nullable(),
  source: z.number(),
  subsource: z.number().optional().nullable(),
  leadPage: z.string().optional().nullable(),
  partyId: z.string().optional().nullable(),
  boatMake: z.string().optional().nullable(),
  boatModel: z.string().optional().nullable(),
  boatYear: z.string().optional().nullable(),
  boatType: z.string().optional().nullable(),
  boatLength: z.string().optional().nullable(),
  boatEngineCount: z.number().optional().nullable(),
  boatEngineMake: z.string().optional().nullable(),
  boatFuelType: z.string().optional().nullable(),
  boatTotalPower: z.number().optional().nullable(),
  shoppingSegment: shoppingSegmentSchema,
  purchasePrice: z.number().min(0),
  downPayment: z.number().min(0),
  loanAmount: z.number().min(0),
  firstName: z.string(),
  lastName: z.string(),
  email: emailSchema,
  phone: z.string().optional().nullable(),
  creditScore: creditScoreSchema,
  sellerName: z.string().optional().nullable(),
  sellerEmail: emailSchema.optional().nullable(),
  sellerType: sellerTypeSchema.optional().nullable(),
  sellerFinanceAdvantage: z.boolean().optional().nullable(),
  prequalApplicationStatus: prequalApplicationStatusSchema.optional().nullable(),
  prequalApprovalStatus: prequalApprovalStatusSchema.optional().nullable(),
  prequalApprovalTerm: z.number().optional().nullable(),
  prequalApprovalRate: z.number().optional().nullable(),
  prequalApprovalMonthlyPayment: z.number().optional().nullable(),
  fullApplicationId: z.string().optional().nullable(),
  fullApplicationStatus: fullApplicationStatusSchema.optional().nullable(),
  fullApplicationStartedAt: z.number().optional().nullable(),
  prequalApplicationId: z.string().optional().nullable(),
  leadUUID: z.string().optional().nullable(),
  leadEmail: emailSchema.optional().nullable(),
  leadApprovalStatus: prequalApprovalStatusSchema.optional().nullable(),
  leadOrigin: leadOriginSchema.optional().nullable(),
  leadCreatedAt: z.number().optional().nullable(),
  createdAt: z.number(),
  updatedAt: z.number().optional().nullable(),
  completedAt: z.number().optional().nullable(),
  utm: prequalUtmSchema.optional().nullable(),
  marketing: loanMarketingSchema.optional().nullable(),
  keyCode: z.string().optional().nullable(),
  promoCode: z.string().optional().nullable(),
  repCode: z.string().optional().nullable(),
  sourceLabel: z.string().optional().nullable(),
  subSourceLabel: z.string().optional().nullable(),
});

export const loanSchema = z.object({
  currentStep: z.string(),
  userId: z.string(),
  createdAt: z.number(),
  submittedAt: z.number().optional().nullable(),
  updatedAt: z.number().optional().nullable(),
  workflow: z.string(),
  id: z.string(),
  partnerLoanId: z.string().optional().nullable(),
  prequalLoanId: z.string().optional().nullable(),
  oldId: z.number().optional().nullable(),
  guid: z.string().optional().nullable(),
  collateral: collateralSchema.optional().nullable(),
  hasTrade: z.boolean().optional().nullable(),
  liveaboard: z.boolean().refine((v) => !v, "Sorry, we don't currently support this option."),
  leadOrigin: leadOriginSchema.optional().nullable(),
  trade: collateralSchema.optional().nullable(),
  purpose: loanPurposeSchema,
  applicantType: applicantTypeSchema,
  ownershipType: ownershipTypeSchema.nullable(),
  associatedName: z.string().optional().nullable(),
  source: z.number(),
  subSource: z.number().optional().nullable(),
  subSourceLabel: z.string().optional().nullable(),
  subSourceName: z.string().optional().nullable(),
  loanType: loanTypeSchema.nullable(),
  shoppingSegment: shoppingSegmentSchema.optional().nullable(),
  requestedDocuments: z.boolean().default(false),
  docUploadUrl: z.string().optional().nullable(),

  // marketing tracking fields primarily supplied through the start url
  showMemberField: z.boolean().optional().nullable(),
  promocode: z.string().max(100).optional().nullable(),
  promocodeEditable: z.boolean().default(true), // passed by "promocode" in url (false if set, true otherwise)
  keycode: z.string().max(100).optional().nullable(),
  member: z.string().max(100).optional().nullable(), // passed by "member" in url
  rateQuoteId: z.string().max(100).optional().nullable(), // passed by "rqid" in url
  salesForceId: z.string().max(100).optional().nullable(), // passed by "sfid" in url
  googleClickId: z.string().max(100).optional().nullable(), // passed by "gclid" in url
  tracking: trackingSchema.optional().nullable(),
  utm: loanUtmSchema.optional().nullable(),
  marketing: loanMarketingSchema.optional().nullable(),
  advertisedRate: z.string().max(100).optional().nullable(), // passed via cookies

  seller: sellerTypeSchema.optional().nullable(),
  sellerName: z.string().max(80).optional().nullable(),
  sellerRepName: z.string().optional().nullable(),
  sellerPhone: phoneSchema.optional().nullable(),
  sellerEmail: emailSchema.optional().nullable(),
  sellerCollateralURL: z.string().optional().nullable(),
  sellerPartyId: z.number().optional().nullable(),
  sellerFinanceAdvantage: z.boolean().optional().nullable(),

  finance: financeDetailsSchema.optional().nullable(),
  borrower: borrowerSchema.optional().nullable(),
  coborrower: borrowerSchema.optional().nullable(),
  addOnServices: z.array(addOnServiceSchema).optional().nullable(),

  thirdPartyDisclaimerSeen: z.boolean().default(false),

  prequal: prequalSchema.optional().nullable(),

  prequalLeadUUID: z.string().optional().nullable(), // prequal uuid
  visitorCode: z.string().optional().nullable(), // Kameleoon visitor code for saved loans
});

export const leadSchema = z.object({
  imtId: z.number().optional().nullable(),
  leadSource: z.string(),
  customer: z.object({
    firstName: z.string().optional().nullable(),
    lastName: z.string().optional().nullable(),
    address: z
      .object({
        zip: z.string().optional().nullable(),
        country: z.string().optional().nullable(),
        city: z.string().optional().nullable(),
        street: z.string().optional().nullable(),
        street2: z.string().optional().nullable(),
        state: z.string().optional().nullable(),
      })
      .optional()
      .nullable(),
    phone: z.object({
      number: z.string().optional().nullable(),
      type: z.string().optional().nullable(),
    }),
    email: emailSchema.optional().nullable(),
  }),
});

export interface SubSource {
  [index: string]: any;

  id: number;
  name: string;
  label?: string;
  products: LoanPurpose[];
  isReferral?: boolean;
}

export interface Source {
  [index: string]: any;

  id: number;
  displayName: string;
  name: string;
  products: LoanPurpose[];
  phone?: string;
  marinePhone?: string;
  secondaryPhone?: string;
  rvPhoneLTCustomer?: string;
  marinePhoneLTCustomer?: string;
  fax?: string;
  marineFax?: string;
  ttyNumber?: string;
  sub: SubSource[];
  theme: Theme;
  hostname: string;
  basePath?: string;
  operationHours?: string;
  operationHoursMarine?: string;
}

export type ThemeColors = {
  // Default Button
  defaultButtonBackground: string;
  defaultButtonBorder: string;
  defaultButtonColor: string;
  defaultButtonHoverBackground: string;
  defaultButtonHoverBorder: string;
  defaultButtonHoverColor: string;

  // Primary Button
  primaryButtonBackground: string;
  primaryButtonBorder: string;
  primaryButtonText: string;
  primaryButtonHoverBackground: string;
  primaryButtonHoverBorder: string;
  primaryButtonHoverText: string;

  // Login Button
  loginButtonBackground: string;
  loginButtonBorder: string;
  loginButtonText: string;
  loginButtonHoverBackground: string;
  loginButtonHoverBorder: string;
  loginButtonHoverText: string;

  // Radiobutton
  radioButtonSelectedBackground: string;
  radioButtonSelectedBorder: string;

  // Other
  footer: string;
  header: string;
  reviewStepHeader: string;
  sectionBar: string;
  selectionBackground: string;
  progressBar: string;

  // StepFooter
  stepFooterBackground: string;
  stepFooterBorder: string;
  stepFooterIconColor: string;
};

export type Theme = {
  colors: ThemeColors;
  logo: string;
  marketingWebsiteUrl: string;
  showMemberField: boolean;
  favicon: string;
};

export type SalesRepsByCode = {
  [code: string]: SalesRep;
};

export interface Office {
  id: number;
  name: string;
  fax?: string;
}

export type OfficeById = {
  [id: number]: Office;
};
export type Address = z.input<typeof addressSchema>;
export type Employer = z.input<typeof employerSchema>;
export type Borrower = z.input<typeof borrowerSchema> & { [key: string]: any };
export type Collateral = z.input<typeof collateralSchema>;
export type FinanceDetails = z.input<typeof financeDetailsSchema>;
export type Asset = z.input<typeof assetSchema>;
export type OtherAsset = z.input<typeof otherAssetSchema>;
export type Liability = z.input<typeof liabilitySchema>;
export type RealEstate = z.input<typeof realEstateSchema>;
export type FinancialStatement = z.input<typeof financialStatementSchema>;
export type Loan = z.input<typeof loanSchema> & { [key: string]: any };
export type Lead = z.input<typeof leadSchema>;
export type Marketing = z.input<typeof loanMarketingSchema>;
export type UTM = z.input<typeof loanUtmSchema>;
export type PreQualUTM = z.input<typeof prequalUtmSchema>;
export type Prequal = z.input<typeof prequalSchema> & { [key: string]: any };

export type ConfigSettings = {
  [key: string]: any;
};

export type ValidLoanMarketingUrlParams = 'adgroup' | 'adgroupid' | 'campaignid' | 'campaign';

export type ValidUTMUrlParams = keyof UTM;
export type ValidPrequalUrlParams =
  | 'keycode'
  | 'leadPage'
  | 'promocode'
  | 'repid'
  | 'source'
  | 'subsource'
  | ValidLoanMarketingUrlParams
  | ValidUTMUrlParams;

export interface Config {
  sources: Array<Source>;
  salesReps: SalesRepsByCode;
  offices: OfficeById;
  settings: ConfigSettings;
  nextSourceId: number;
  nextSubSourceId: number;
}

export interface SalesRep {
  code: string;
  last: string;
  phone: string;
  purpose: LoanPurpose;
  office: string;
  first: string;
  email: string;
  username: string;
  // old app ids
  officeId: number;
  id: number;
}

export type Model = {
  id: number;
  name: string;
  seriesName?: string;
  lengthInches?: number;
  fuel?: string;
};

export type Make = {
  id: number;
  make: string;
  models?: Array<Model>;
};

export type KameleoonExperiment = {
  id: string;
  variation: string;
};

export type FeatureFlag = {
  id: number;
  key: string;
  variation: string;
};

/**
 * @description All **UTM** params that the Loan Application funnel should recognize and act on _(i.e. save to Loan object's utm property)_
 */
export const VALID_UTM_PARAMS = new Set<ValidUTMUrlParams>([
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_name',
  'utm_source',
  'utm_term',
]);

/**
 * @description All Marketing-specific url params that the Loan Application and PreQual funnel should recognize and act on _(i.e. save to the object's marketing property)_
 */
export const VALID_MARKETING_PARAMS = new Set<ValidLoanMarketingUrlParams>([
  'adgroup',
  'adgroupid',
  'campaignid',
  'campaign',
]);

/**
 * @description **All** url params that the PreQual funnel should recognize and act on _(i.e. save to PreQual store)_
 * @todo Once we upgrade TS, we can combine this const with `VALID_UTM_PARAMS` and the `VALID_MARKETING_PARAMS`
 */
export const VALID_PREQUAL_URL_PARAMS = new Set<ValidPrequalUrlParams>([
  'keycode',
  'leadPage',
  'promocode',
  'repid',
  'source',
  'subsource',

  // Should be same values as in `VALID_MARKETING_PARAMS`
  'adgroup',
  'adgroupid',
  'campaign',
  'campaignid',

  // Should be same values as in `VALID_UTM_PARAMS`
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_name',
  'utm_source',
  'utm_term',
]);

export const PREVIOUS_ADDRESS_REQUIRED_YEARS = 3;
export const PREVIOUS_EMPLOYER_REQUIRED_YEARS = 2;

export function loanRequiresFinancialStatement(loan: Loan) {
  if (!loan) return false;
  if (loan.finance?.financedAmount === undefined || loan.finance?.financedAmount === null)
    return false;
  if (
    loan.collateral?.type === RVType.classBCustom ||
    loan.collateral?.type === RVType.adventureOverland
  )
    return true;
  return loan.finance?.financedAmount >= LOAN_AMOUNT_FOR_FINANCIAL_STATEMENT;
}

export function borrowerRequiresEmployer(borrower?: Partial<Borrower> | null) {
  if (!borrower) return false;
  return [
    EmploymentStatus.employed,
    EmploymentStatus.selfEmployed,
    EmploymentStatus.unemployed,
  ].includes(borrower.employmentStatus || EmploymentStatus.employed);
}

export function borrowerRequiresPreviousEmployer(borrower?: Partial<Borrower> | null) {
  if (!borrower) return false;
  if (
    borrower.employmentStatus === EmploymentStatus.retired &&
    borrower.retiredYears !== null &&
    borrower.retiredYears !== undefined &&
    borrower.retiredYears < 2
  ) {
    return true;
  }
  return (
    [EmploymentStatus.employed, EmploymentStatus.selfEmployed].includes(
      borrower.employmentStatus || EmploymentStatus.employed
    ) &&
    borrower.currentEmployer?.years !== undefined &&
    borrower.currentEmployer.years !== null &&
    borrower.currentEmployer.years < PREVIOUS_EMPLOYER_REQUIRED_YEARS
  );
}

export function validateLoan(loan: Loan): void {
  // don't validate objects that are not required
  if (loan.applicantType === ApplicantType.individual) loan.coborrower = null;
  if (!loan.hasTrade) loan.trade = null;
  [loan.borrower, loan.coborrower].forEach((borrower) => {
    if (!borrower) return;

    if (borrower.sharesAddress) {
      borrower.currentAddress = null;
      borrower.previousAddress = null;
    }

    borrower.currentAddress =
      borrower.currentAddress && Object.keys(borrower.currentAddress).length
        ? borrower.currentAddress
        : null;
    borrower.previousAddress =
      borrower.previousAddress && Object.keys(borrower.previousAddress).length
        ? borrower.previousAddress
        : null;
    borrower.mailingAddress =
      borrower.mailingAddress && Object.keys(borrower.mailingAddress).length
        ? borrower.mailingAddress
        : null;

    if (!loanRequiresFinancialStatement(loan)) {
      borrower.financialStatement = null;
    }

    if (!borrowerRequiresEmployer(borrower)) {
      borrower.currentEmployer = null;
      borrower.currentEmployer2 = null;
    }
    if (!borrowerRequiresPreviousEmployer(borrower)) {
      borrower.previousEmployer = null;
    }

    if (borrower.hasMailingAddress === false) borrower.mailingAddress = null;
    if (
      borrower.currentAddress?.yearsAtResidence !== undefined &&
      borrower.currentAddress.yearsAtResidence !== null &&
      borrower.currentAddress.yearsAtResidence >= PREVIOUS_ADDRESS_REQUIRED_YEARS
    ) {
      borrower.previousAddress = null;
    }
  });

  const loanSchemaWithoutPrequal = loanSchema.omit({
    prequal: true,
  });

  loanSchemaWithoutPrequal.parse(loan);
}

import React, { useEffect, useState } from 'react';
import { Form, Modal, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import {
  pushToDataLayer,
  sendCustomEventToIterable,
  formatIterablePayload,
  calculateBuyingPower,
  formatEpochDate,
} from '../../utils';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import RadioButton from '../components/RadioButton/RadioButton';
import { stepFinished } from '../../prequalNav';
import firebase from 'firebase/compat/app';
import {
  Prequal,
  CreditScoreRangeType,
  PrequalApplicationStatus,
  PrequalApprovalStatus,
} from '../../schema';
import FooterCard from '../components/FooterCard/FooterCard';
import styles from './FicoStep.module.scss';
import { deleteCookie } from '../../utils/cookies';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const FicoStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = useForm();

  const updateValues = async (values: any) => {
    setSubmitting(true);
    const castedPrequal = prequal as Prequal;
    const purchasePrice = await calculateBuyingPower(castedPrequal);
    const functions = firebase.functions();
    const completePrequal = functions.httpsCallable('completePrequal');
    prequalStore.setFicoScore(values);
    prequalStore.setPurchasePrice({ purchasePrice: purchasePrice.toString() });
    prequalStore.setDownPayment({
      downPayment: castedPrequal.downPayment.toString(),
    });
    await Promise.allSettled([
      completePrequal({ prequal: castedPrequal }),
      sendCustomEventToIterable({
        email: prequal.email as string,
        eventName: 'PreQualCompleted',
        eventData: formatIterablePayload(castedPrequal, {
          applicationStatus: PrequalApplicationStatus.submitted,
          approvalStatus: prequal.prequalApprovalStatus,
          startedAt: prequal.createdAt ? formatEpochDate(prequal.createdAt) : undefined,
          completedAt: prequal.completedAt ? formatEpochDate(prequal.completedAt) : undefined,
          ...(castedPrequal.imtId === 'NONBDP' && { boatImtId: null }),
          ...(prequal.prequalApprovalStatus === PrequalApprovalStatus.approved && {
            desiredMonthlyPayment: prequal.prequalApprovalMonthlyPayment,
            downPayment: prequal.downPayment,
            loanAmount: prequal.loanAmount,
            term: prequal.prequalApprovalTerm,
            interestRate: prequal.prequalApprovalRate,
            purchasingPower: prequal.purchasePrice,
          }),
        }),
      }),
    ]);
    deleteCookie('utmParamsCookie');
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Fico Score - Continue',
    });
    setSubmitting(false);
    stepFinished();
  };

  const checkFicoScoreRange = () => {
    const values = form.getFieldsValue();
    setCanContinue(values.creditScore);
    const creditScoreRangeType = CreditScoreRangeType;
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: `${
        creditScoreRangeType[values.creditScore as keyof typeof creditScoreRangeType]
      }`,
    });
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'fico',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    if (values.creditScore) {
      setCanContinue(true);
    }
  }, [prequal]);

  const header = {
    title: 'About You',
    subtitle: "What's your credit score?",
    content:
      'Your credit score will help us pre-qualify you. There is no impact to your credit score.',
  };

  return (
    <PreQualFunnelLayout>
      <Modal visible={submitting} footer={null} closable={false}>
        <p>Submitting your application...</p>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Modal>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={updateValues}
        onValuesChange={checkFicoScoreRange}
      >
        <RadioButton name="creditScore" type={CreditScoreRangeType} useKeyAsValue />
        <NavigationRow canContinue={canContinue} form={form} />
      </Form>

      <FooterCard />
    </PreQualFunnelLayout>
  );
});

export default FicoStep;

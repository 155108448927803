import React from 'react';
import { Form } from 'antd';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import IncomeForm from '../components/IncomeForm';
import { numSalaryIncomeSources } from '../../utils';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const CoBorrowerIncomeStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const coborrower = loanStore.getCoBorrower();

  const update = (values: any) => {
    if (numSalaryIncomeSources(values) < 2 && loan?.coborrower?.currentEmployer2) {
      runInAction(() => {
        if (loan.coborrower) {
          loan.coborrower.currentEmployer2 = null;
        }
      });
    }
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      <h1>We need a few financial details for your co-borrower</h1>
      <Form form={form} {...formItemLayout} initialValues={coborrower} onFinish={update}>
        <IncomeForm borrower={coborrower} />

        <ButtonNav />
      </Form>
    </LoanLayout>
  );
});

export default CoBorrowerIncomeStep;

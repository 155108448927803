import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import EmployerForm from '../components/EmployerForm';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import { Employer, IncomeSource } from '../../schema';

type Props = {};

const CoBorrowerEmployerStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const employer = loanStore.getCoBorrowerEmployer();
  const showAdditionalEmployer =
    loan?.coborrower?.primaryIncomeSource === IncomeSource.salary &&
    loan?.coborrower?.secondaryIncomeSource === IncomeSource.salary;
  const additionalEmployer = showAdditionalEmployer
    ? loanStore.getCoBorrowerEmployer2()
    : ({} as Employer);

  const update = (_values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      <h1>Co-borrower&apos;s Employment Information</h1>
      <Form form={form} onFinish={update}>
        <EmployerForm employer={employer} form={form} isCoBorrower />
        {showAdditionalEmployer && (
          <>
            <br />
            <h1>Additional Employment Information</h1>
            <EmployerForm
              employer={additionalEmployer}
              form={form}
              formItemPrefix="additional-"
              isCoBorrower
            />
          </>
        )}

        <ButtonNav />
      </Form>
    </LoanLayout>
  );
});

export default CoBorrowerEmployerStep;

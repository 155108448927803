import React, { useEffect, useState, useCallback } from 'react';
import { Form, Radio, Modal, Checkbox, Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { rowLayout } from '../layout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import BorrowerAddressForm from '../components/BorrowerAddressForm';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import {
  borrowerSchema,
  Address,
  ApplicantType,
  LoanPurpose,
  FullApplicationStatus,
  Prequal,
  LeadOrigin,
} from '../../schema';
import { createSchemaValidator } from '../../validators/schemaValidator';
import AppFormItem from '../components/AppFormItem';
import {
  capitalizeFirst,
  processAddressUpdates,
  showPreviousAddressBorrower,
  showPreviousAddressCoborrower,
  shouldHideMonthsAtResidenceBorrower,
  shouldHideMonthsAtResidenceCoborrower,
  areAddressesEqual,
  sendCustomEventToIterable,
  formatIterablePayload,
  formatEpochDate,
} from '../../utils';
import styles from './BorrowerAddress.module.scss';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';

type Props = {};

const schema = borrowerSchema.pick({
  hasMailingAddress: true,
});
const schemaValidator = createSchemaValidator(schema);

const BorrowerAddressStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  const shouldShowBorrowerPreviousAddress = useCallback(showPreviousAddressBorrower, [
    loan?.borrower?.currentAddress?.yearsAtResidence,
    loanStore,
  ]);

  const shouldShowCoborrowerPreviousAddress = useCallback(showPreviousAddressCoborrower, [
    loan?.coborrower?.currentAddress?.yearsAtResidence,
    loan?.applicantType,
    loan?.coborrower?.sharesAddress,
    loanStore,
  ]);

  const [showBorrowerPreviousAddress, setShowBorrowerPreviousAddress] = useState(
    shouldShowBorrowerPreviousAddress(
      null,
      loan,
      loanStore,
      form.getFieldValue('borrowerCurrentAddressYearsAtResidence') ||
        loan?.borrower?.currentAddress?.yearsAtResidence
    )
  );
  const [showCoborrowerPreviousAddress, setShowCoborrowerPreviousAddress] = useState(
    shouldShowCoborrowerPreviousAddress(
      null,
      loan,
      loanStore,
      form.getFieldValue('coborrowerCurrentAddressYearsAtResidence')
    )
  );
  const [hideMonthsAtResidenceBorrower, setHideMonthsAtResidenceBorrower] = useState(
    shouldHideMonthsAtResidenceBorrower(null, loan)
  );
  const [hideMonthsAtResidenceCoborrower, setHideMonthsAtResidenceCoborrower] = useState(
    shouldHideMonthsAtResidenceCoborrower(null, loan)
  );

  const showMailingAddress = loan?.borrower?.hasMailingAddress === true;
  const showCoborrowerAddress = loan?.applicantType === ApplicantType.joint;

  const mapAddressToFormFields = (address: Address | FormAddress, prefix: string): any => {
    if (Object.keys(address).length === 0) return address;

    const revisedKeys =
      prefix === 'coborrowerSharesAddress'
        ? Object.keys(address).map((key) => `co${key}`)
        : Object.keys(address).map((key) => `${prefix}${capitalizeFirst(key)}`);
    const originalKeys = Object.keys(address);
    const formAddress: FormAddress = {};

    for (let i = 0; i < revisedKeys.length; i++) {
      formAddress[revisedKeys[i] as keyof FormAddress] = address[originalKeys[i] as keyof Address];
    }

    return formAddress;
  };

  const pickAddressFormValues = (formValues: any, prefix: string): FormAddress =>
    Object.keys(formValues)
      .filter((key) => key.startsWith(prefix))
      .reduce(
        (obj, key) =>
          Object.assign(obj, {
            [key]: formValues[key],
          }),
        {}
      );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sharesAddressChange = (_changed: any, values: any) => {
    let coborrowerCurrentAddress;
    if (values?.sharesAddress === true) {
      const borrowerAddressFormValues: FormAddress = pickAddressFormValues(
        values,
        'borrowerCurrentAddress'
      );
      coborrowerCurrentAddress = mapAddressToFormFields(
        borrowerAddressFormValues,
        'coborrowerSharesAddress'
      );
      form.setFieldsValue(coborrowerCurrentAddress);
      loanStore.setNestedValue('coborrower', 'currentAddress', {
        address1: coborrowerCurrentAddress.coborrowerCurrentAddressAddress1,
        city: coborrowerCurrentAddress.coborrowerCurrentAddressCity,
        county: coborrowerCurrentAddress.coborrowerCurrentAddressCounty,
        country: coborrowerCurrentAddress.coborrowerCurrentAddressCountry,
        state: coborrowerCurrentAddress.coborrowerCurrentAddressState,
        zip: coborrowerCurrentAddress.coborrowerCurrentAddressZip,
        yearsAtResidence: coborrowerCurrentAddress.coborrowerCurrentAddressYearsAtResidence,
        monthsAtResidence: coborrowerCurrentAddress.coborrowerCurrentAddressMonthsAtResidence,
        mortgageRentPayment: coborrowerCurrentAddress.coborrowerCurrentAddressMortgageRentPayment,
        rentOrOwn: coborrowerCurrentAddress.coborrowerCurrentAddressRentOrOwn,
        unitNumber: coborrowerCurrentAddress.coborrowerCurrentAddressUnitNumber,
      });
    } else {
      form.setFieldsValue({
        coborrowerCurrentAddressZip: null,
        coborrowerCurrentAddressCountry: null,
        coborrowerCurrentAddressCity: null,
        coborrowerCurrentAddressAddress1: null,
        coborrowerCurrentAddressState: null,
        coborrowerCurrentAddressYearsAtResidence: null,
        coborrowerCurrentAddressMonthsAtResidence: null,
        coborrowerCurrentAddressMortgageRentPayment: 0,
        coborrowerCurrentAddressRentOrOwn: null,
        coborrowerCurrentAddressUnitNumber: null,
      });
      loanStore.setNestedValue('coborrower', 'currentAddress', null);
      loanStore.setNestedValue('coborrower', 'previousAddress', null);
    }
  };

  useEffect(() => {
    setShowBorrowerPreviousAddress(
      shouldShowBorrowerPreviousAddress(
        null,
        loan,
        loanStore,
        form.getFieldValue('borrowerCurrentAddressYearsAtResidence') ||
          loan?.borrower?.currentAddress?.yearsAtResidence
      )
    );
    setShowCoborrowerPreviousAddress(
      shouldShowCoborrowerPreviousAddress(
        null,
        loan,
        loanStore,
        form.getFieldValue('coborrowerCurrentAddressYearsAtResidence') ||
          loan?.coborrower?.currentAddress?.yearsAtResidence
      )
    );
    setHideMonthsAtResidenceBorrower(shouldHideMonthsAtResidenceBorrower(null, loan));
    setHideMonthsAtResidenceCoborrower(shouldHideMonthsAtResidenceCoborrower(null, loan));
  }, [
    shouldShowBorrowerPreviousAddress,
    shouldShowCoborrowerPreviousAddress,
    loan,
    loanStore,
    sharesAddressChange,
    form,
  ]);

  useEffect(() => {
    if (loan?.leadOrigin !== LeadOrigin.prequal) {
      sendCustomEventToIterable({
        email: loan?.borrower?.email as string,
        eventName: 'FullAppStarted',
        eventData: formatIterablePayload({} as Prequal, {
          applicationStatus: FullApplicationStatus.started,
          startedAt: formatEpochDate(Date.now()),
          applicationId: loan?.id,
          firstName: loan?.borrower?.firstName,
          lastName: loan?.borrower?.lastName,
          boatMake: loan?.collateral?.manufacturer,
          boatModel: loan?.collateral?.modelNumber,
          sellerName: loan?.sellerName,
          sellerEmail: loan?.sellerEmail,
          sellerPartyId: loan?.sellerPartyId,
          boatImtId: loan?.collateral?.imtId,
          sellerType: loan?.seller,
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type FormAddress = {
    [key: string]: any;
  };

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const borrower = loanStore.getBorrower();
  const coborrower = loanStore.getCoBorrower();

  const borrowerAddress = mapAddressToFormFields(
    loanStore.getBorrowerAddress(),
    'borrowerCurrentAddress'
  );

  const borrowerPreviousAddress = mapAddressToFormFields(
    loanStore.getBorrowerPreviousAddress(),
    'borrowerPreviousAddress'
  );

  const borrowerMailingAddress = mapAddressToFormFields(
    loanStore.getBorrowerMailingAddress(),
    'borrowerMailingAddress'
  );

  const coborrowerAddress = coborrower?.sharesAddress
    ? mapAddressToFormFields(loanStore.getBorrowerAddress(), 'coborrowerCurrentAddress')
    : mapAddressToFormFields(loanStore.getCoBorrowerAddress(), 'coborrowerCurrentAddress');

  const coborrowerPreviousAddress = mapAddressToFormFields(
    loanStore.getCoBorrowerPreviousAddress(),
    'coborrowerPreviousAddress'
  );

  const processMortgageRentPayment = (values: any): void => {
    if (typeof values.borrowerCurrentAddressMortgageRentPayment === 'string') {
      if (values.borrowerCurrentAddressMortgageRentPayment === '') {
        values.borrowerCurrentAddressMortgageRentPayment = 0;
      } else {
        values.borrowerCurrentAddressMortgageRentPayment = parseFloat(
          values.borrowerCurrentAddressMortgageRentPayment.replace(/[$ ,]/g, '')
        );
      }
    }

    if (typeof values.borrowerPreviousAddressMortgageRentPayment === 'string') {
      if (values.borrowerPreviousAddressMortgageRentPayment === '') {
        values.borrowerPreviousAddressMortgageRentPayment = 0;
      } else {
        values.borrowerPreviousAddressMortgageRentPayment = parseFloat(
          values.borrowerPreviousAddressMortgageRentPayment.replace(/[$ ,]/g, '')
        );
      }
    }

    if (typeof values.coborrowerCurrentAddressMortgageRentPayment === 'string') {
      if (values.coborrowerCurrentAddressMortgageRentPayment === '') {
        values.coborrowerCurrentAddressMortgageRentPayment = 0;
      } else {
        values.coborrowerCurrentAddressMortgageRentPayment = parseFloat(
          values.coborrowerCurrentAddressMortgageRentPayment.replace(/[$ ,]/g, '')
        );
      }
    }

    if (typeof values.coborrowerPreviousAddressMortgageRentPayment === 'string') {
      if (values.coborrowerPreviousAddressMortgageRentPayment === '') {
        values.coborrowerPreviousAddressMortgageRentPayment = 0;
      } else {
        values.coborrowerPreviousAddressMortgageRentPayment = parseFloat(
          values.coborrowerPreviousAddressMortgageRentPayment.replace(/[$ ,]/g, '')
        );
      }
    }
  };

  const handleChange = (changed: any, values: any) => {
    if (
      (values.borrowerCurrentAddressMortgageRentPayment == null ||
        values.borrowerCurrentAddressMortgageRentPayment === '$ ') &&
      (values.borrowerCurrentAddressRentOrOwn === 'military' ||
        values.borrowerCurrentAddressRentOrOwn === 'own' ||
        values.borrowerCurrentAddressRentOrOwn === 'friend')
    ) {
      values.borrowerCurrentAddressMortgageRentPayment = 0;
      form.setFieldsValue(values);
    }

    if (
      (values.borrowerPreviousAddressMortgageRentPayment == null ||
        values.borrowerPreviousAddressMortgageRentPayment === '$ ') &&
      (values.borrowerPreviousAddressRentOrOwn === 'military' ||
        values.borrowerPreviousAddressRentOrOwn === 'own' ||
        values.borrowerPreviousAddressRentOrOwn === 'friend')
    ) {
      values.borrowerPreviousAddressMortgageRentPayment = 0;
      form.setFieldsValue(values);
    }

    if (
      (values.coborrowerCurrentAddressMortgageRentPayment == null ||
        values.coborrowerCurrentAddressMortgageRentPayment === '$ ') &&
      (values.coborrowerCurrentAddressRentOrOwn === 'military' ||
        values.coborrowerCurrentAddressRentOrOwn === 'own' ||
        values.coborrowerCurrentAddressRentOrOwn === 'friend')
    ) {
      values.coborrowerCurrentAddressMortgageRentPayment = 0;
      form.setFieldsValue(values);
    }

    if (
      (values.coborrowerPreviousAddressMortgageRentPayment == null ||
        values.coborrowerPreviousAddressMortgageRentPayment === '$ ') &&
      (values.coborrowerPreviousAddressRentOrOwn === 'military' ||
        values.coborrowerPreviousAddressRentOrOwn === 'own' ||
        values.coborrowerPreviousAddressRentOrOwn === 'friend')
    ) {
      values.coborrowerPreviousAddressMortgageRentPayment = 0;
      form.setFieldsValue(values);
    }

    processMortgageRentPayment(values);
    setShowBorrowerPreviousAddress(
      shouldShowBorrowerPreviousAddress(
        values,
        loan,
        loanStore,
        form.getFieldValue('borrowerCurrentAddressYearsAtResidence') ||
          loan?.borrower?.currentAddress?.yearsAtResidence
      )
    );
    setShowCoborrowerPreviousAddress(
      shouldShowCoborrowerPreviousAddress(
        values,
        loan,
        loanStore,
        form.getFieldValue('coborrowerCurrentAddressYearsAtResidence') ||
          loan?.coborrower?.currentAddress?.yearsAtResidence
      )
    );
    setHideMonthsAtResidenceBorrower(shouldHideMonthsAtResidenceBorrower(values, loan));
    setHideMonthsAtResidenceCoborrower(shouldHideMonthsAtResidenceCoborrower(values, loan));
    form.setFieldsValue(values);
    if (Object.keys(changed || {}).includes('sharesAddress')) {
      sharesAddressChange(changed, values);
      setShowCoborrowerPreviousAddress(
        shouldShowCoborrowerPreviousAddress(
          values,
          loan,
          loanStore,
          form.getFieldValue('coborrowerCurrentAddressYearsAtResidence') ||
            loan?.coborrower?.currentAddress?.yearsAtResidence
        )
      );
    }
    processAddressUpdates(
      form.getFieldsValue(true),
      loanStore,
      showBorrowerPreviousAddress,
      showMailingAddress,
      showCoborrowerAddress,
      showCoborrowerPreviousAddress
    );
  };

  const update = (values: any) => {
    processAddressUpdates(
      values,
      loanStore,
      showBorrowerPreviousAddress,
      showMailingAddress,
      showCoborrowerAddress,
      showCoborrowerPreviousAddress
    );

    handleChange(null, values);

    const currentAddress = loanStore.getBorrowerAddress();
    const previousAddress = loanStore.getBorrowerPreviousAddress();
    const currentAddressCo = loanStore.getCoBorrowerAddress();
    const previousAddressCo = loanStore.getCoBorrowerPreviousAddress();

    if (areAddressesEqual(currentAddress, previousAddress)) {
      Modal.error({
        title: 'Address Error',
        content: <p>Your Previous address matches Your Current address.</p>,
      });
    } else if (areAddressesEqual(currentAddressCo, previousAddressCo)) {
      Modal.error({
        title: 'Address Error',
        content: <p>Co-borrower's Previous address matches Co-borrower's Current address.</p>,
      });
    } else {
      // noinspection JSIgnoredPromiseFromCall
      stepFinished();
    }
  };

  const initialValues = {
    ...borrowerAddress,
    ...borrowerPreviousAddress,
    ...borrowerMailingAddress,
    ...coborrowerAddress,
    ...coborrowerPreviousAddress,
    hasMailingAddress: borrower.hasMailingAddress,
    sharesAddress: coborrower.sharesAddress,
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat ? 'Tell us a little about you' : 'Your Home Address'}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>Enter your home address</p>}
      <Form
        form={form}
        onFinish={update}
        initialValues={initialValues}
        onValuesChange={handleChange}
      >
        <BorrowerAddressForm
          form={form}
          borrower={borrower}
          address={borrowerAddress}
          type="borrowerCurrentAddress"
          hideMonthsAtResidence={hideMonthsAtResidenceBorrower}
          county
          rentOwn
        />

        <Row>
          <AppFormItem
            className="manual-label"
            name="hasMailingAddress"
            required
            hasFeedback={false}
            rules={[
              { required: true, message: 'Please select one' },
              schemaValidator('hasMailingAddress', borrower),
            ]}
          >
            <ManualLabel label="Do you have a different mailing address?" twoLine>
              <Radio.Group
                className={styles.borrowerAddressRadioGroup}
                optionType="button"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
              />
            </ManualLabel>
          </AppFormItem>
        </Row>

        {showMailingAddress && (
          <>
            <h2 style={{ marginTop: '.4375rem' }}>Your Mailing Address</h2>
            <BorrowerAddressForm
              form={form}
              borrower={borrower}
              address={borrowerMailingAddress}
              askYears={false}
              type="borrowerMailingAddress"
            />
          </>
        )}

        {showBorrowerPreviousAddress && (
          <>
            <h2 className="alternateAddressTitle">
              {loan?.purpose === LoanPurpose.boat
                ? 'Enter your previous home address'
                : 'Your Previous Address'}
            </h2>
            <Row>
              <BorrowerAddressForm
                form={form}
                borrower={borrower}
                address={borrowerPreviousAddress}
                type="borrowerPreviousAddress"
                rentOwn
              />
            </Row>
          </>
        )}

        {showCoborrowerAddress && (
          <>
            <h2 className="alternateAddressTitle">Co-borrower&apos;s Home Address</h2>
            <Row {...rowLayout} style={{ marginBottom: '1.312rem' }}>
              <Col xs={24} sm={24} md={24} lg={24}>
                <AppFormItem
                  className="manual-label"
                  name="sharesAddress"
                  valuePropName="checked"
                  wrapperCol={{ span: 24, offset: 20 }}
                  noStyle
                >
                  <Checkbox>Same address as borrower address?</Checkbox>
                </AppFormItem>
              </Col>
            </Row>
            <Row>
              <BorrowerAddressForm
                form={form}
                borrower={coborrower}
                address={coborrowerAddress}
                type="coborrowerCurrentAddress"
                hideMonthsAtResidence={hideMonthsAtResidenceCoborrower}
                rentOwn
                disabled={Boolean(coborrower.sharesAddress)}
                hasFeedback={Boolean(coborrower.sharesAddress)}
              />
            </Row>
          </>
        )}

        {showCoborrowerPreviousAddress && (
          <>
            <h2 className="alternateAddressTitle">Co-borrower&apos;s Previous Address</h2>
            <Row>
              <BorrowerAddressForm
                form={form}
                borrower={coborrower}
                address={coborrowerPreviousAddress}
                type="coborrowerPreviousAddress"
                rentOwn
              />
            </Row>
          </>
        )}

        <ButtonNav />
        <StepFooter type="secure" />
      </Form>
    </LoanLayout>
  );
});

export default BorrowerAddressStep;

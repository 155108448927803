/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement, useEffect } from 'react';
import { Row, Col, Form, Input, Modal, Radio, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { stepFinished } from '../../nav';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from './meta';
import AppFormItem from '../components/AppFormItem';
import { Loan, LoanPurpose, LoanType, OwnershipType, SubSource } from '../../schema';
import { ColProps } from 'antd/lib/grid/col';
import useConfig from '../../stores/config';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';

const optionsColWrapper: ColProps = {
  sm: { span: 12, offset: 0 },
  md: { span: 12, offset: 0 },
  lg: { span: 12, offset: 0 },
};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const schemaValidator = createSchemaValidator(pickFields('details'));

const setUserCanPickSubSource = (loan: Loan) => {
  if (loan) {
    runInAction(() => {
      loan.userCanPickSubsource = loan?.userCanPickSubsource || !loan?.subSource;
    });
  }
};

const DetailsStep = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const config = useConfig();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  useEffect(() => {
    setUserCanPickSubSource(loan as Loan);
  }, []);

  if (!loan || loanStore.status === 'pending' || !config) {
    return <Loading />;
  }

  let subSources: SubSource[] = null as unknown as SubSource[];
  let showSubsourceDropdown = true;

  try {
    const source = config.getSource(loan.source);
    subSources = source.sub
      .filter((ss) => ss.products.includes(loan.purpose))
      .filter((ss) => !ss.isReferral);

    if ((!loan.userCanPickSubsource && loan.subSource) || loan.purpose === LoanPurpose.boat) {
      showSubsourceDropdown = false;
    }
  } catch (e) {
    console.error(e);
  }

  if (!subSources) {
    subSources = config.sources[0].sub;
  }

  const update = () => {
    const { subSource } = loan;
    if (subSource !== null && subSource !== undefined) {
      // store the label as well
      for (let i = 0; i < subSources.length; i++) {
        if (subSources[i].id === subSource) {
          loan.subSourceLabel = subSources[i].name;
        }
      }
    }
    loanStore.updateDetails();
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  const handleChange = (changed: any, values: any) => {
    if (values.liveaboard) {
      Modal.error({
        title: 'Important Information',
        content: (
          <>
            {loan.purpose === LoanPurpose.boat && (
              <p>
                We're sorry, at this time we do not offer marine loans for Live-aboards. Thank you.
              </p>
            )}
            {loan.purpose === LoanPurpose.rv && (
              <p>We're sorry, at this time we do not offer RV loans for Full-Timers. Thank you.</p>
            )}
          </>
        ),
      });

      values.liveaboard = false;
    }
  };

  const ownershipTypeOptions: ReactElement[] = [];
  Object.values(OwnershipType).forEach((label) => {
    if (label === OwnershipType.montanaLlc && loan.purpose !== LoanPurpose.rv) return;
    ownershipTypeOptions.push(
      <Select.Option key={label} value={label}>
        {label}
      </Select.Option>
    );
  });

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">Boat info</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat
          ? 'Qualify for a loan in less than 5 minutes'
          : 'A few questions about your loan:'}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>Tell us about the boat you'd like to finance</p>}
      <Row>
        <Col {...centerSmallOffset} />
        <Col {...centerSmall}>
          <Form form={form} initialValues={loan} onFinish={update} onValuesChange={handleChange}>
            <AppFormItem
              className="manual-label loan-type"
              name="loanType"
              required
              rules={[schemaValidator('loanType', loan, { message: 'Please select one' })]}
            >
              <PlaceholderLabel label="What type of loan are you interested in?" htmlFor="loanType">
                <Select
                  suffixIcon={<SelectArrowIcon />}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key={LoanType.new} value={LoanType.new}>
                    New Purchase
                  </Select.Option>
                  <Select.Option key={LoanType.used} value={LoanType.used}>
                    Used Purchase
                  </Select.Option>
                  <Select.Option key={LoanType.refinance} value={LoanType.refinance}>
                    Refinance
                  </Select.Option>
                  <Select.Option key={LoanType.cashRecapture} value={LoanType.cashRecapture}>
                    Cash Recapture/Cash Out
                  </Select.Option>
                </Select>
              </PlaceholderLabel>
            </AppFormItem>

            {loan.purpose !== LoanPurpose.aircraft && (
              <AppFormItem
                className="manual-label"
                name="liveaboard"
                hasFeedback={false}
                required
                rules={[
                  schemaValidator('liveaboard', loan, {
                    message: 'Please select one',
                  }),
                ]}
                wrapperCol={optionsColWrapper}
              >
                <ManualLabel twoLine label={`Will this ${loan.purpose} be used for:`}>
                  <Radio.Group
                    optionType="button"
                    options={[
                      { label: 'Pleasure', value: false },
                      {
                        label: loan.purpose === LoanPurpose.rv ? 'Fulltimer' : 'Liveaboard',
                        value: true,
                      },
                    ]}
                  />
                </ManualLabel>
              </AppFormItem>
            )}

            {showSubsourceDropdown && (
              <AppFormItem
                name="subSource"
                rules={[schemaValidator('subSource', loan, { transform: 'int' })]}
                hasFeedback
              >
                <PlaceholderLabel label="How did you hear about us?" htmlFor="subSource">
                  <Select
                    suffixIcon={<SelectArrowIcon />}
                    showSearch={subSources?.length > 7}
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {subSources.map((s) => (
                      <Select.Option key={String(s.id)} value={s.id}>
                        {s.label || s.name}
                      </Select.Option>
                    ))}
                  </Select>
                </PlaceholderLabel>
              </AppFormItem>
            )}

            {loan.subSource === 5 && (
              <AppFormItem
                name="subSourceName"
                rules={[schemaValidator('subSourceName', loan)]}
                hasFeedback
              >
                <PlaceholderLabel label="How you heard of us? (optional)" htmlFor="subSourceName">
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            )}

            {loan.purpose !== LoanPurpose.boat && loan.promocodeEditable && (
              <AppFormItem
                name="promocode"
                rules={[schemaValidator('promocode', loan)]}
                hasFeedback
              >
                <PlaceholderLabel label="Promocode (optional)" htmlFor="promocode">
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            )}

            {loan.showMemberField && (
              <AppFormItem name="member" rules={[schemaValidator('member', loan)]} hasFeedback>
                <PlaceholderLabel label="Member Number" htmlFor="member">
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            )}

            <ButtonNav />
            <StepFooter type="info" />
          </Form>
        </Col>
      </Row>
    </LoanLayout>
  );
});

export default DetailsStep;

/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import styles from './SplashPage.module.scss';
import { Col, Row } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../stores/store';
import useConfig from '../stores/config';
import { generateUrlParams, pushToDataLayer } from '../utils';
import { useTheme } from '../hooks/useTheme';
import { ReactComponent as MedalIcon } from '../icons/prequal_medal.svg';
import { ReactComponent as BoatIcon } from '../icons/prequal_boat.svg';
import { ReactComponent as HandshakeIcon } from '../icons/prequal_handshake.svg';
import { ReactComponent as StarIcon } from '../icons/prequal_star.svg';
import { AppFooter } from './components/AppFooter/AppFooter';
import { BOAT_TRADER_SOURCE_ID, isBoatTrader, isDev } from '../constants';

import headerStyles from '../prequal/prequal.module.scss';
import { FooterCardProps } from '../prequal/steps/PurchaseTimeframeStep';
import footerStyles from '../prequal/steps/PurchaseTimeframeStep.module.scss';
import { LoanPurpose } from '../schema';

const overrideURLParams = (ctaLink: string, utmParams: any) => {
  const [linkUrl, linkParams] = ctaLink.split('?');
  const urlParams = new URLSearchParams(linkParams);

  // Append default utmParams
  urlParams.append('purpose', LoanPurpose.boat);
  urlParams.append('source', BOAT_TRADER_SOURCE_ID.toString());

  for (const key in utmParams) {
    if (utmParams[key] !== null) {
      if (!urlParams.has(key)) {
        urlParams.append(key, utmParams[key]);
      } else {
        urlParams.set(key, utmParams[key]);
      }
    }
  }
  return `${linkUrl}?${urlParams.toString()}`;
};

const SplashPageHeader = observer(() => {
  const loanStore = useLoan();
  const config = useConfig();
  const urlQueryParams = generateUrlParams(null);
  const theme = useTheme(loanStore, config, true);

  return (
    <div className={styles.pqSplashHeaderContainer}>
      <Header className={styles.pqSplashHeader}>
        <Row className={styles.pqSplashHeaderRow}>
          <Col>
            <a
              href={`${
                theme?.marketingWebsiteUrl || 'https://www.tridentfunding.com'
              }${urlQueryParams}`}
              data-testid="logo"
            >
              <img
                className={headerStyles.logoMobile}
                height="25"
                width="300"
                src="/static/trident/boattrader-trident-logo-blue.png"
                alt="logo"
              />
            </a>
          </Col>
        </Row>
      </Header>
    </div>
  );
});

SplashPageHeader.displayName = 'SplashPageHeader';

const SplashPagePreQualCard: React.FC<FooterCardProps> = (props) => {
  const { icon, title, text } = props;

  return (
    <div className={styles.pqSplashFooterCard}>
      <div className={styles.pqSplashFooterCardContentContainer}>
        <div className="icon">{icon}</div>
        <div className="header">
          <span className={styles.pqSplashFooterCardTitle}>{title}</span>
        </div>
      </div>
      <div className="text">{text}</div>
    </div>
  );
};

type SplashPageReviewCardProps = {
  author: string;
  numStars?: number;
  text: string;
  title: string;
};

const SplashPageReviewCard: React.FC<SplashPageReviewCardProps> = (props) => {
  const { title, author, text, numStars = 5 } = props;
  const starsToShow = Array.from(Array(numStars).keys());

  return (
    <div className={styles.pqSplashReviewFooterCard}>
      <div>
        <h3>{title}</h3>
        <p className={styles.pqSplashReviewFooterCardAuthor}>{author}</p>
      </div>
      <div>
        {starsToShow.map((i) => (
          <StarIcon key={`${Date.now()}-${i}`} />
        ))}
      </div>
      <div className={styles.pqSplashReviewFooterCardContentContainer}>
        <p className={styles.pqSplashReviewFooterCardContent}>"{text}"</p>
      </div>
    </div>
  );
};

const GetPreQualifiedCards = () => (
  <div className={footerStyles.cardContainer}>
    <SplashPagePreQualCard
      icon={<BoatIcon />}
      title="We compare so you save"
      text="We'll compare over 15 marine lenders to get you the best rate and terms"
    />
    <SplashPagePreQualCard
      icon={<MedalIcon />}
      title="Seasoned Lending Experts"
      text="Our financing experts are seasoned veterans in marine lending"
    />
    <SplashPagePreQualCard
      icon={<HandshakeIcon />}
      title="Personalized Financing"
      text="We’ll tailor a solution to meet your unique financial needs"
    />
  </div>
);

const SplashPage: React.FC = () => {
  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'finance full app splash page',
      site_section: 'loan application',
    });
  }, []);

  const params = new URLSearchParams(window.location.search);

  return (
    <div className={styles.pqSplashContentContainer}>
      <div className={styles.pqSplashContent}>
        <SplashPageHeader />

        <div className={styles.pqSplashHero}>
          <img
            src="/static/trident/pre-qual-splash-image.jpeg"
            alt="Hero"
            className={styles.pqSplashHeroImage}
          />
          <div className={styles.pqSplashHeroContentText}>
            <h1 className={styles.pqSplashHeroText}>Financing Made Easy</h1>
            <h2 className={styles.pqSplashHeroText}>Customized financing with seasoned experts</h2>
            <div className={styles.pqSplashHeroCTA}>
              <a
                className={styles.pqSplashGetStartedButton}
                href={overrideURLParams(
                  `${isDev() ? '/apply/' : `${isBoatTrader() ? '/boat-loans' : ''}/apply/`}${
                    window.location.search
                  }`,
                  {
                    utm_campaign: params.get('utm_campaign'),
                    utm_medium: params.get('utm_medium'),
                    utm_content: params.get('utm_content'),
                  }
                )}
                rel="noreferrer"
                onClick={() =>
                  pushToDataLayer('link_click', {
                    action_type: 'click',
                    action_label: 'get started apply enter',
                  })
                }
              >
                Apply now
              </a>
            </div>
          </div>
        </div>

        <div className={styles.pqSplashCenterContent}>
          <h1 className={styles.pqSplashGetPreQualHeaderText}>Quick application process</h1>
          <GetPreQualifiedCards />
        </div>

        <div className={styles.pqSplashBottomContent}>
          <h1>Over 100,000 approved boat loans and counting</h1>
          <h3 className={styles.pqSplashBottomContentExpl}>
            Read what customers have to say about our services, transparency, and customer service
            <img
              className={styles.pqTrustPilotLogo}
              height="16"
              width="67"
              src="/static/trident/trust-pilot-logo.svg"
              alt="trust-pilot-logo"
            />
          </h3>
          <div className={styles.pqSplashCardContainer}>
            <SplashPageReviewCard
              author="Fred Rice"
              text="From day one I was treated as if my request was always a priority with my banker handling my request. I was always informed of any and all changes and bank requests. When the bank asked for more info, my agent was fast to respond and very helpful in informing me of the needs. I was taken care of as if I was the only customer she had."
              title="From day one I was treated like I was the only customer. I was treated as if this loan was top priority."
            />
            <SplashPageReviewCard
              author="Tim Cosgrove"
              text="This is the third or fourth loan I have worked with Trident Funding on. Every time I work with them they make it easier. Karen in the Annapolis office has helped me each time. Karens quick response and professionalism make what could be a daunting experience smooth. I can not tell you how strongly I recommend Karen and Trident funding if you need funds for a boat or airplane or anything fun for that matter."
              title="This is the third or fourth loan I have"
            />
          </div>
        </div>
        <AppFooter />
      </div>
    </div>
  );
};

export default SplashPage;

/* eslint-disable import/no-cycle */
import { ConfigStore } from '../stores/config';
import { PrequalStore } from '../stores/prequalStore';
import {
  Prequal,
  PrequalApplicationStatus,
  PrequalApprovalStatus,
  FullApplicationStatus,
} from '../schema';
import { betterParseInt, betterParseFloat } from '.';
import {
  isProduction,
  PROD_TRIDENT_API_URL,
  QA_TRIDENT_API_URL,
  BOAT_TRADER_SOURCE_ID,
} from '../constants';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { getNumericQueryParam } from './queryParamHelper';

const addBoatDetails = () => {
  const params = new URLSearchParams(window.location.search);
  const boatInfo = params.get('boatInfo');

  if (boatInfo) {
    try {
      const boatDetails = JSON.parse(decodeURIComponent(boatInfo));
      return {
        imtId: boatDetails.imtId,
        partyId: boatDetails.partyId,
        purchasePrice: boatDetails.price,
        boatMake: boatDetails.make,
        boatModel: boatDetails.model,
        boatYear: boatDetails.year,
        boatType: boatDetails.type,
        boatLength: boatDetails.length,
        boatEngineCount: boatDetails.engineCount,
        boatEngineMake: boatDetails.engineMake,
        boatFuelType: boatDetails.fuelType,
        boatTotalPower: boatDetails.totalPower,
        sellerName: boatDetails.sellerName,
        sellerEmail: boatDetails.sellerEmail,
        sellerType: boatDetails.sellerType,
        sellerFinanceAdvantage: boatDetails.sellerFinanceAdvantage,
      };
    } catch (e) {
      console.error('Error parsing boatInfo:', e);
    }
  }
  return {};
};

const determineLoanType = (prequal: Prequal) => {
  const currentYear = new Date().getFullYear();
  if (prequal.imtId === 'NONBDP' || betterParseInt(prequal.boatYear) === currentYear) {
    return 'new';
  }

  return 'used';
};

/**
 * Generates a universally unique user ID (UUID).
 *
 * @return {string} The generated UUID.
 */
export const generateUUID = () => uuidv4();

export const isPrequalFunnel = () => window.location.pathname.includes('/pre-qualify');

export const formatEpochDate = (epochTime: number) => {
  const date = new Date(epochTime);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const getApprovalStatus = (rate: string): PrequalApprovalStatus =>
  rate === '-' || rate.toLowerCase() === 'call'
    ? PrequalApprovalStatus.notApproved
    : PrequalApprovalStatus.approved;

/**
 * Cleans the prequal results if Not Approved or an error occurs
 *
 * @param {Prequal} prequal - The prequal object.
 */
const cleanPrequalApproval = (prequal: Prequal) => {
  delete prequal.prequalApprovalTerm;
  delete prequal.prequalApprovalRate;
  delete prequal.prequalApprovalMonthlyPayment;
};

/**
 * Determines the prequal status by making an API call to the /ratesCalculator endpoint.
 *
 * @param {Prequal} prequal - The prequal object.
 * @return {Promise<void>} A promise that resolves when the get request is complete.
 */
export const determinePrequalStatus = async (prequal: Prequal): Promise<void> => {
  const apiUrl = isProduction()
    ? `${PROD_TRIDENT_API_URL}/rates-calculator/0/0/Boat`
    : `${QA_TRIDENT_API_URL}/rates-calculator/0/0/Boat`;
  try {
    const response = await axios.get(apiUrl, {
      params: {
        loanType: determineLoanType(prequal),
        loanAmount: prequal.loanAmount,
        modelYear: prequal.boatYear || new Date().getFullYear(),
        ficoScoreRange: prequal.creditScore,
      },
    });

    const { monthlyPayment, rate, term }: { monthlyPayment: string; rate: string; term: string } =
      response.data;

    prequal.prequalApprovalStatus = getApprovalStatus(rate);

    if (prequal.prequalApprovalStatus === 'Not Approved') {
      cleanPrequalApproval(prequal);
      return;
    }

    prequal.prequalApprovalTerm = betterParseInt(term);
    prequal.prequalApprovalRate = betterParseFloat(rate);
    prequal.prequalApprovalMonthlyPayment = betterParseFloat(monthlyPayment);
  } catch (error) {
    console.error('Error determining prequal approval status:', error);
    prequal.prequalApprovalStatus = PrequalApprovalStatus.notApproved;
    cleanPrequalApproval(prequal);
  }
};

export const calculateTotalLoanAmount = (rate: number, term: number, monthlyPayment: number) => {
  const ratePerMonth = rate / 100 / 12;
  const x = (1 + ratePerMonth) ** term;
  const loanAmount =
    x * ratePerMonth === 0
      ? (monthlyPayment * term).toFixed(2)
      : ((monthlyPayment * (x - 1)) / (x * ratePerMonth)).toFixed(2);
  const totalPayments = (term * monthlyPayment).toFixed(2);
  const totalInterest = (Number(totalPayments) - Number(loanAmount)).toFixed(2);

  return {
    monthlyPayment,
    totalPayments,
    loanAmount,
    totalInterest,
  };
};

/**
 * Calculates purchase price for a desired monthly payment, term, and rate.
 * Makes an API call to the /rates endpoint to retrieve teaser rate
 * Uses 20 years as default term
 *
 * @param {Prequal} prequal - The prequal object.
 * @return {Promise<number>} A promise that resolves when the get request is complete.
 */
export const calculateBuyingPower = async (prequal: Prequal): Promise<number> => {
  const apiUrl = isProduction()
    ? `${PROD_TRIDENT_API_URL}/rates/0/0/Boat`
    : `${QA_TRIDENT_API_URL}/rates/0/0/Boat`;
  try {
    const response = await axios.get(apiUrl, {
      params: {
        loanType: determineLoanType(prequal),
      },
    });

    const { teaserRate }: { teaserRate: string } = response?.data?.[0] ?? {
      teaserRate: '7.12%',
    };

    prequal.prequalApprovalStatus = getApprovalStatus(teaserRate);
    prequal.prequalApprovalTerm = 240;
    prequal.prequalApprovalRate = betterParseFloat(teaserRate);

    const { loanAmount } = calculateTotalLoanAmount(
      Number(prequal.prequalApprovalRate),
      prequal.prequalApprovalTerm,
      Number(prequal.prequalApprovalMonthlyPayment)
    );
    return Number(loanAmount) + Number(prequal.downPayment);
  } catch (error) {
    console.error('Error determining prequal approval status:', error);
    prequal.prequalApprovalStatus = PrequalApprovalStatus.notApproved;
    cleanPrequalApproval(prequal);
    return 0;
  }
};

/**
 * Retrieves prequal data from local storage or generates new data based on prequalStore
 *
 * @param {PrequalStore} prequalStore - the PrequalStore object containing prequal data
 * @return {Prequal | {}} the retrieved or generated prequal data
 */
export const getPrequal = (prequalStore: PrequalStore) => {
  const { isBDP } = prequalStore;
  const imtId = prequalStore.imtId || 'NONBDP';
  const storageKey = `prequal-${imtId}`;
  const storedPrequal = localStorage.getItem(storageKey);
  let loadedPrequal: Partial<Prequal>;

  if (storedPrequal) {
    loadedPrequal = JSON.parse(storedPrequal);
  } else {
    if (isBDP) {
      loadedPrequal = addBoatDetails();
    } else {
      loadedPrequal = { imtId: 'NONBDP' };
    }

    loadedPrequal = {
      ...loadedPrequal,
      source: prequalStore.source || BOAT_TRADER_SOURCE_ID,
      subsource: prequalStore.subsource,
      prequalApplicationId: prequalStore.prequalApplicationId,
      utm: {
        utmCampaign: prequalStore?.utm?.utmCampaign,
        utmContent: prequalStore?.utm?.utmContent,
        utmId: prequalStore?.utm.utmId,
        utmMedium: prequalStore?.utm.utmMedium,
        utmName: prequalStore?.utm.utmName,
        utmSource: prequalStore?.utm.utmSource,
        utmTerm: prequalStore?.utm?.utmTerm,
      },
      leadPage: prequalStore.leadPage,
      leadOrigin: prequalStore?.leadOrigin,
      phone: null,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      prequalApplicationStatus: PrequalApplicationStatus.started,
      prequalApprovalStatus: null,
      prequalApprovalTerm: null,
      prequalApprovalRate: null,
      prequalApprovalMonthlyPayment: null,
      fullApplicationId: null,
      fullApplicationStatus: FullApplicationStatus.notStarted,
      fullApplicationStartedAt: null,
      leadUUID: null,
      leadEmail: null,
      leadApprovalStatus: null,
      leadCreatedAt: null,
      completedAt: null,
      marketing: prequalStore?.marketing || null,
      keyCode: prequalStore?.keyCode,
      promoCode: prequalStore?.promoCode,
      repCode: prequalStore?.repCode,
      sourceLabel: prequalStore?.sourceLabel,
      subSourceLabel: prequalStore?.subSourceLabel,
    };

    localStorage.setItem(storageKey, JSON.stringify(loadedPrequal));
  }

  return loadedPrequal;
};

/**
 * Saves the prequal data to local storage using the IMT ID as the storage key.
 * IMT ID can be 'NONBDP' if the prequal is not from BDP
 *
 * @param {Prequal} prequal - the prequal data to be saved
 * @return {void}
 */
export const savePrequal = (prequal: Prequal) => {
  const storageKey = `prequal-${prequal.imtId}`;
  prequal.updatedAt = Date.now();
  localStorage.setItem(storageKey, JSON.stringify(prequal));
};

export const camelCaseMarketingParam = (name: string) => {
  let camelCasedName = '';
  const paramName = name.toLowerCase();

  switch (paramName) {
    case 'adgroup':
      camelCasedName = 'adGroup';
      break;
    case 'adgroupid':
      camelCasedName = 'adGroupId';
      break;
    case 'campaignid':
      camelCasedName = 'campaignId';
      break;
    case 'campaign':
      camelCasedName = 'campaignName';
      break;
    default:
      camelCasedName = paramName;
  }

  return camelCasedName;
};

export const getSource = (config: ConfigStore | null, prequal: Partial<Prequal>): number => {
  let sourceId: number | undefined = BOAT_TRADER_SOURCE_ID;

  if (config) {
    const sourceIdParam = getNumericQueryParam({ param: 'source' });

    if (prequal?.source) {
      sourceId = prequal.source;
    } else if (sourceIdParam) {
      sourceId = sourceIdParam;
    } else {
      const source = config.getSourceFromLocation(window.location.href);
      sourceId = source && source.id === 0 ? 0 : source?.id || sourceId;
    }
  }

  return sourceId;
};

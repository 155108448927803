import React, { useState } from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import BorrowerContactForm from '../components/BorrowerContactForm';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { ApplicantType, Borrower, LoanPurpose } from '../../schema';
import { runInAction } from 'mobx';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';

const { Text } = Typography;

type Props = {};

const BorrowerContactStep: React.FC<Props> = observer(() => {
  const [showPhoneNumberMessage, setShowPhoneNumberMessage] = useState(false);
  const [form] = Form.useForm();
  const loanStore = useLoan();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });
  const { loan } = loanStore;

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const borrower = loanStore.getBorrower();

  const onFinish = (_values: any) => {
    if (
      loan.applicantType === ApplicantType.individual &&
      Object.keys(loan?.coborrower || ({} as any)).length > 0
    ) {
      runInAction(() => {
        loan.coborrower = {} as Borrower;
      });
    }

    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  const validatePhoneFields: React.FormEventHandler<HTMLFormElement> = (e) => {
    const homePhone = form.getFieldValue('homePhone');
    const cellPhone = form.getFieldValue('cellPhone');

    if (!homePhone && !cellPhone) {
      e.preventDefault();
      e.stopPropagation();
      setShowPhoneNumberMessage(true);
    } else {
      setShowPhoneNumberMessage(false);
    }
  };

  const onValueChange = (changedValues: any) => {
    if (['homePhone', 'cellPhone'].includes(Object.keys(changedValues)[0])) {
      setShowPhoneNumberMessage(false);
    }
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat
          ? 'Tell us a little about you'
          : 'How do we contact you?'}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>How do we contact you about your loan status?</p>}
      <Form
        form={form}
        onValuesChange={onValueChange}
        onFinish={onFinish}
        onSubmitCapture={validatePhoneFields}
      >
        <BorrowerContactForm borrower={borrower} showPrivacyPolicy form={form} />
        {showPhoneNumberMessage && (
          <Row>
            <Col sm={0} md={4} />
            <Text type="danger" id="no-phone-message">
              You must enter a phone number.
            </Text>
          </Row>
        )}
        <ButtonNav />
        <StepFooter type="secure" />
      </Form>
    </LoanLayout>
  );
});

export default BorrowerContactStep;

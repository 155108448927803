import firebase from 'firebase/compat/app';
import { Prequal } from '../schema';

interface EventData {
  [key: string]: any;
}

interface SendCustomEventParams {
  email: string;
  eventName: string;
  eventData?: EventData;
}

export const sendCustomEventToIterable = async ({
  email,
  eventName,
  eventData,
}: SendCustomEventParams): Promise<void> => {
  const functions = firebase.functions();
  const sendCustomEventToIterableFn = functions.httpsCallable('sendCustomEventToIterable');
  try {
    await sendCustomEventToIterableFn({ email, eventName, eventData });
  } catch (error: any) {
    console.error('sendCustomEventToIterable | Error:', error);
  }
};

export const formatIterablePayload = (prequal: Prequal, overrides: { [key: string]: any }) => {
  const payload = Object.fromEntries(
    Object.entries({
      prequalId: prequal.prequalApplicationId,
      email: prequal.email,
      firstName: prequal.firstName,
      lastName: prequal.lastName,
      boatMake: prequal.boatMake,
      boatModel: prequal.boatModel,
      sellerName: prequal.sellerName,
      sellerEmail: prequal.sellerEmail,
      sellerPartyId: prequal.partyId,
      boatImtId: prequal.imtId,
      sellerType: prequal.sellerType,
      ...overrides,
    }).filter(([, v]) => v !== null && v !== undefined)
  );

  return payload;
};

import React, { ReactElement, useState } from 'react';
import { Checkbox, Col, Form, Input, message, Modal, Row, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import { fetchRepidPhone, renderMainNumber } from '../components/AppFooter/PhoneNumbers';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { createSchemaValidator } from '../../validators/schemaValidator';
import {
  ApplicantType,
  Borrower,
  borrowerSchema,
  FullApplicationStatus,
  Prequal,
} from '../../schema';
import { gotoNextStep, gotoStep } from '../../nav';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import AppFormItem from '../components/AppFormItem';
import useHistory from '../../hooks/useHistory';
import useConfig from '../../stores/config';
import {
  pushToDataLayer,
  formatEpochDate,
  sendCustomEventToIterable,
  formatIterablePayload,
} from '../../utils';
import { FF_FULL_APPLICATION_STYLING, isProduction } from '../../constants';
import getSource from '../../utils/getSource';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { deleteCookie } from '../../utils/cookies';

declare const fbq: any;
type Props = {};

const schemaValidator = createSchemaValidator(borrowerSchema);

const SubmitStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const config = useConfig();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const isFromPrequal = () => (loan?.prequalLoanId ?? null) !== null;

  const onValuesChange = (values: any) => {
    if (!loan.borrower?.firstName || !loan.borrower.lastName) {
      message.error('Missing name info!');
      gotoStep(history, 'borrower');
      return;
    }
    if (values.borrower?.disclaimerAccepted && !values.borrower?.signatureDate) {
      values.borrower.signature = `${loan.borrower?.firstName} ${loan.borrower?.lastName}`;
      values.borrower.signatureDate = new Date().toLocaleDateString('en-US');
      form.setFieldsValue(values);
    }
    if (values.coborrower?.disclaimerAccepted && !values.coborrower?.signatureDate) {
      values.coborrower.signature = `${loan.coborrower?.firstName} ${loan.coborrower?.lastName}`;
      values.coborrower.signatureDate = new Date().toLocaleDateString('en-US');
      form.setFieldsValue(values);
    }
    loanStore.setSignatures(values);

    runInAction(() => {
      delete loan.userCanPickSubsource;
    });
  };

  const borrower = loan.borrower || ({} as Borrower);
  const coborrower = loan.coborrower || ({} as Borrower);
  const isJointApplicant = loan.applicantType === ApplicantType.joint;

  let areDisclaimersAccepted = borrower.disclaimerAccepted;
  let areSignaturesValid = borrower?.signature && borrower?.signature.trim().length > 0;
  if (isJointApplicant) {
    areDisclaimersAccepted = areDisclaimersAccepted && coborrower.disclaimerAccepted;
    areSignaturesValid =
      areSignaturesValid && coborrower?.signature && coborrower?.signature.trim().length > 0;
  }

  const update = (values: any) => {
    if (loan.workflow !== 'draft') {
      gotoNextStep(history, 'success');
      return;
    }

    setSubmitting(true);
    onValuesChange(values);
    loanStore
      .submit()
      .then((data: any) => {
        if (!data.success) {
          console.error(data);
          setSubmitting(false);
          const renderedErrors: ReactElement[] = [];
          if (data.issues) {
            for (let i = 0; i < data.issues.length; i++) {
              const issue = data.issues[i];
              if (issue.message === 'Required') {
                renderedErrors.push(<li>{issue.path.join('/')} is required</li>);
              } else {
                renderedErrors.push(
                  <li>
                    {issue.path.join('/')} {issue.message}
                  </li>
                );
              }
            }
          }
          loanStore.setError(
            <div>
              Save failed with validation errors!
              <br />
              <ul>{renderedErrors}</ul>
            </div>
          );
          gotoStep(history, 'review');
        } else {
          localStorage.removeItem('loanId');
          const source = config?.getSource(loan.source);
          pushToDataLayer('application_submitted', {
            ecommerce: {
              currencyCode: 'USD',
              purchase: {
                actionField: {
                  id: loan?.id,
                  affiliation: source?.name?.toLowerCase(),
                  revenue: loan.finance?.financedAmount,
                },
                products: [
                  {
                    name: loan.purpose.toLowerCase(),
                    id: loan.purpose.toLowerCase(),
                    price: loan.finance?.financedAmount,
                    category: (loan?.loanType || '').toLowerCase(),
                  },
                ],
              },
            },
          });

          sendCustomEventToIterable({
            email: loan?.borrower?.email as string,
            eventName: 'FullAppCompleted',
            eventData: formatIterablePayload({} as Prequal, {
              applicationStatus: FullApplicationStatus.submitted,
              completedAt: formatEpochDate(Date.now()),
              applicationId: loan?.id,
              ...(isFromPrequal() && { prequalId: loan.prequalLoanId }),
              firstName: loan?.borrower?.firstName,
              lastName: loan?.borrower?.lastName,
              boatMake: loan?.collateral?.manufacturer,
              boatModel: loan?.collateral?.modelNumber,
              sellerName: loan?.sellerName,
              sellerEmail: loan?.sellerEmail,
              sellerPartyId: loan?.sellerPartyId,
              boatImtId: loan?.collateral?.imtId,
              sellerType: loan?.seller,
            }),
          });

          deleteCookie('utmParamsCookie');
          window.kameleoonQueue.push(['Goals.processConversion', 'custom-trident-loan-submit']);
          if (isProduction() && typeof fbq !== 'undefined') fbq('track', 'Submit');
          // cannot save after submitting without a permission error
          gotoNextStep(history, 'success');
        }
      })
      .catch((e: any) => {
        const { source } = getSource(config, loan as any);
        const repIdPhone = fetchRepidPhone(loan?.repCode, config?.salesReps);
        const mainNumber = renderMainNumber(loan?.purpose, source, repIdPhone, '');

        console.error(e);
        setSubmitting(false);
        Modal.error({
          title: 'Error',
          content: (
            <p>
              There was an error submitting your loan application, please try again. If this still
              does not work please contact support at {mainNumber}.
              <br />
              <br />
              <b>Loan ID:</b> {loan.id}
              {e.message && (
                <>
                  <br />
                  <br />
                  {e.message}
                </>
              )}
            </p>
          ),
        });
      });
  };

  // flatten for the form
  const initialValues = {
    borrower: loan.borrower,
    coborrower: loan.coborrower,
  };

  return (
    <LoanLayout className="submitLayout">
      {isNewFunnel && <div className="step-title">Submit your Application</div>}
      <Modal visible={submitting} footer={null} closable={false}>
        <p>Submitting your application...</p>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Modal>
      <h1>All done! Just one last thing</h1>
      <Form
        form={form}
        onFinish={update}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <p style={{ textAlign: 'center' }}>CREDIT APPLICATION DISCLOSURE</p>
        <div className="disclaimer">
          <p className="header">
            <span>I.</span>
            <span>
              ELECTRONIC DISCLOSURE CONSENT FOR ELECTRONIC DISCLOSURES <br />
              ELECTRONIC SIGNATURES IN GLOBAL AND NATIONAL COMMERCE ACT
            </span>
          </p>
          <p>Please read important disclosure information below:</p>
          <p>
            <strong>Introduction:</strong> You are submitting an electronic application of credit
            relating to the financing of a vehicle (hereinafter “Application”). In order to do this,
            we need your consent to our giving certain disclosures electronically. This document
            informs you of your rights when receiving legally required disclosures, notices, and
            (“Disclosures”) from us and the Financial Institutions to whom, your Application is
            submitted. By completing and submitting an Application through us, you acknowledge
            receipt of this document and consent to the electronic delivery of such Disclosures.
          </p>
          <p>
            <strong>Electronic Communications:</strong> Any Disclosures related to your Application
            will be provided to you electronically. However, if you wish to obtain a paper copy of
            any of the Disclosures, you may send an email to{' '}
            <a href="mailto: info@TridentFunding.com" className="link">
              info@TridentFunding.com
            </a>{' '}
            with the details of your request. Trident Funding does not currently offer an option for
            alert notifications.
          </p>
          <p>
            <strong>Consenting to do Business Electronically:</strong> Before you decide to do
            business electronically with us, you should consider whether you have the required
            hardware and software capabilities described in this document.
          </p>
          <p>
            <strong>Scope of Content:</strong> Your consent to receive Disclosures and to do
            business electronically, and our agreement to do so, only applies to this Application.
            You may receive Disclosures from Trident Funding and you may also receive Disclosures
            and other communications from our participating Lenders. After your Application is
            transmitted to one or more of our participating Lenders, and after you decide to
            continue to pursue your Application, then you and the Lender should discuss how
            subsequent Disclosures will be delivered.
          </p>
          <p>
            <strong>Hardware and Software Requirements:</strong> To access and retain the
            Disclosures electronically, you will need to use the following computer software and
            hardware: Internet Explorer 7.0 or above or above or equivalent software; and hardware
            capable of running this software.
          </p>
          <p>
            <strong>Insurance Requirement:</strong> If this application is approved by a lender, the
            applicant will be required to obtain and maintain physical damage insurance on the
            collateral securing the debt. The applicant has a right of free choice in the selection
            of the agent and insurer through or by which the insurance is placed and can provide his
            or own insurance coverage at any point during the term of the loan. Obtaining insurance
            products from a particular agent or broker will not affect the credit decision.
          </p>
          <p>
            <strong>Withdrawing Consent:</strong> Because we will provide the Disclosures to you
            instantaneously, you will not be able to withdraw your consent to do business
            electronically with us. However, you may withdraw your consent to do business
            electronically with our participating Lenders at no cost to you. You may do so by
            contacting the Lender at the mailing address, e-mail address or telephone number they
            provide with their offer. If you decide to withdraw your consent, the legal validity and
            enforceability of prior electronic Disclosures will not be affected.
          </p>
          <p>
            <strong>Changes to Your Contact Information:</strong> You should keep us informed of any
            change in your electronic or mailing address. You may update your information by sending
            an email to{' '}
            <a href="mailto: info@TridentFunding.com" className="link">
              info@TridentFunding.com
            </a>{' '}
            regarding any such changes.
          </p>
          <p>
            <strong>YOUR ABILITY TO ACCESS DISCLOSURES.</strong> BY COMPLETING AND SUBMITTING YOUR
            APPLICATION, YOU ACKNOWLEDGE THAT YOU CAN ACCESS THE ELECTRONIC DISCLOSURES IN THE
            DESIGNATED FORMATS DESCRIBED ABOVE.
          </p>
          <p>
            <strong>CONSENT.</strong> BY COMPLETING AND SUBMITTING YOUR APPLICATION, YOU CONSENT TO
            HAVING ALL DISCLOSURES PROVIDED OR MADE AVAILABLE TO YOU IN ELECTRONIC FORM AND TO DOING
            BUSINESS WITH US ELECTRONICALLY.
          </p>
          <p className="header">
            <span>II.</span>
            <span>ADDITIONAL DISCLOSURE</span>
          </p>
          <p>
            <strong>Representations:</strong> By electronically signing this Application you certify
            that all the representation contained in this Application are true and correct and
            acknowledge that this is an online application and that you are providing permission to
            obtain a credit report. In the event the representations are false, you understand that
            you may be prosecuted both criminally and civilly and such misrepresentation may result
            in your purchased vehicle being repossessed and/or the partial or complete loss of any
            down payment and/or trade-in provided in accordance with prevailing law.{' '}
          </p>
          <p>
            <strong>Affiliate Sharing:</strong> All affiliates of Trident Funding LLC, offer a wide
            array Services to choose from. You may express interest in applying for financing on
            different vehicles at one or more of our affiliates' locations. With your consent, we
            may share, for your convenience, the personal information we collect about you with our
            affiliates in order to help process your transaction and assist in getting your
            application for financing program approved by a third-party bank or finance company. By
            signing below, you authorize the sharing of information in this Application, your credit
            report, and other creditworthiness information with affiliates of Trident Funding LLC
            for the purposes of determining whether you qualify for financing. Other than sharing
            information pursuant to this consent, the shared information remains subject to our{' '}
            <a
              href="https://www.tridentfunding.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              Privacy Notice - Trident Funding LLC
            </a>
            .
          </p>
          <p>
            <strong>Consent to Contact:</strong> You agree that the creditor, the creditor's
            affiliates, agents and service providers may monitor and record telephone calls
            regarding my account to assure the quality of the creditor's service or for other
            reasons. You also expressly consent and agree to the creditor, the creditor's
            affiliates, agents and service providers using written, electronic or verbal means to
            contact you. This consent includes, but is not limited to, contact by manual calling
            methods, prerecorded or artificial voice messages, text messages, emails and/or
            automatic telephone dialing systems. You agree the creditor, the creditor's affiliates,
            agents and service providers may do so using any e-mail address or any telephone number
            you provide, now or in the future, including a number for a cellular phone or other
            wireless device, regardless of whether you incur charges as a result.
          </p>
          <p className="header">
            <span>III.</span>
            <span>FEDERAL NOTICES</span>
          </p>
          <p>IMPORTANT INFORMATION ABOUT PROCEDURES FOR NEW APPLICATIONS</p>
          <p>
            <strong>PATRIOT ACT NOTICE:</strong> To help the government fight the funding of
            terrorism and money laundering activities, Federal law requires all financial
            institutions to obtain, verify, and record information that identifies each person who
            opens an account. What this means for each applicant: When applicant opens an account,
            the Trident Funding LLC will ask for applicant's name, address, date of birth, and other
            information that will allow the Lender to identify applicant. The Lender may also ask to
            see applicant's driver's license or other identifying documents.
          </p>
          <p className="header">
            <span>IV.</span>
            <span>STATE NOTICES</span>
          </p>
          <p>
            <strong>
              Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington or
              Wisconsin Residents:
            </strong>{' '}
            If you, the applicant, are married and live in a community property state, you should
            also provide the personal credit information on your spouse in the co-applicant section.
            Your spouse is not required to be a co-applicant for the credit requested unless he/she
            wishes to be a co-applicant.
          </p>
          <p>
            <strong>California Residents:</strong> Each applicant, if married, may apply for a
            separate account.
          </p>
          <p>
            <strong>New York Residents:</strong> A consumer credit report may be requested in
            connection with this application or with updates, renewals or extensions of any credit
            granted as a result of this application. If you subsequently ask for this information,
            you will be informed whether or not such a report was requested and, if so, the name and
            address of the agency that furnished the report.
          </p>
          <p>
            <strong>Ohio Residents:</strong> The Ohio laws against discrimination require that all
            creditors make credit equally available to all creditworthy customers and that credit
            reporting agencies maintain separate credit histories on each individual upon request.
            The Ohio Civil Rights Commission administers compliance with this law.
          </p>
          <p>
            <strong>New Hampshire Residents:</strong> If this is an application for balloon
            financing, you are entitled to receive, upon request, a written estimate of the monthly
            payment amount that would be required to refinance the balloon payment at the time such
            payment is due based on the creditor's current refinancing programs.{' '}
          </p>
          <p>
            <strong>New Hampshire Residents:</strong> In connection with your application for
            credit, we may request a consumer report that contains information on your credit
            worthiness, credit standing, personal characteristics and general reputation. If we
            grant you credit, we or our servicer may order additional consumer reports in connection
            with any update, renewal or extension of the credit. If you ask us, we will tell you
            whether we obtained a consumer report, and if we did, we will tell you the name and
            address of the consumer reporting agency that gave us the report.
          </p>
          <p>
            <strong>Vermont Residents:</strong> By clicking on "I Agree / Submit", you authorize us
            and our employees or agents to obtain and verify information about you (including one or
            more credit reports, information about your employment and banking and credit
            relationships) that we may deem necessary or appropriate in evaluating your application.
            If your application is approved and credit is extended, you also authorize us, and our
            employees and agents, to obtain additional credit reports and other information about
            you in connection with reviewing the account, increasing the available credit on the
            account (if applicable), taking collection on the account, or for any other legitimate
            purpose.
          </p>
          <p>
            <strong>Married Wisconsin Residents:</strong> Wisconsin law provides that no provision
            of any marital property agreement, or unilateral statement, or court order applied to
            marital property will adversely affect a creditor's interests unless, prior to the time
            that the credit is granted, the creditor is furnished with a copy of the agreement,
            statement or decree, or has actual knowledge of the adverse provision. If you are making
            this application individually and not jointly with your spouse, the full name and
            current address of your spouse must be properly disclosed in the co- applicant section
            of this application.
          </p>
        </div>

        <AppFormItem
          className="manual-label"
          name={['borrower', 'disclaimerAccepted']}
          valuePropName="checked"
          wrapperCol={{ span: 24, offset: 0 }}
          rules={[
            schemaValidator('disclaimerAccepted', borrower, {
              message: 'Please accept the terms',
            }),
          ]}
          validateTrigger="onChange"
          hasFeedback={false}
        >
          <Checkbox>
            By my signature below, I acknowledge that I have read and agree to the above.
          </Checkbox>
        </AppFormItem>

        <Row>
          <Col span={14}>
            <AppFormItem
              name={['borrower', 'signature']}
              required
              rules={[
                schemaValidator('signature', borrower, {
                  message: 'Please type your full name',
                }),
              ]}
            >
              <PlaceholderLabel label="Borrower Signature" htmlFor="borrower_signature">
                <Input />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
          <Col span={9} offset={1}>
            <AppFormItem
              name={['borrower', 'signatureDate']}
              required
              rules={[
                schemaValidator('signatureDate', borrower, {
                  message: 'Please enter the date',
                }),
              ]}
            >
              <PlaceholderLabel label="Date" htmlFor="borrower_signatureDate">
                <Input disabled />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>

        {isJointApplicant && (
          <>
            <AppFormItem
              className="manual-label"
              name={['coborrower', 'disclaimerAccepted']}
              valuePropName="checked"
              wrapperCol={{ span: 24, offset: 0 }}
              rules={[
                schemaValidator('disclaimerAccepted', coborrower, {
                  message: 'Please accept the terms',
                }),
              ]}
              validateTrigger="onChange"
              hasFeedback={false}
            >
              <Checkbox>
                By my signature below, I acknowledge that I have read and agree to the above.
              </Checkbox>
            </AppFormItem>

            <Row>
              <Col span={14}>
                <AppFormItem
                  name={['coborrower', 'signature']}
                  required
                  rules={[
                    schemaValidator('signature', coborrower, {
                      message: 'Please type your full name',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="coborrower Signature" htmlFor="coborrower_signature">
                    <Input />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
              <Col span={9} offset={1}>
                <AppFormItem
                  name={['coborrower', 'signatureDate']}
                  required
                  rules={[
                    schemaValidator('signatureDate', coborrower, {
                      message: 'Please enter the date',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="Date" htmlFor="coborrower_signatureDate">
                    <Input disabled />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </Row>
          </>
        )}

        <ButtonNav isNextDisabled={!(areDisclaimersAccepted && areSignaturesValid)} />
      </Form>
    </LoanLayout>
  );
});

export default SubmitStep;

import React, { useState } from 'react';
import { Button, Form, Input, Modal, Row, Select, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { toJS } from 'mobx';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import { formatCurrency } from '../../utils';
import { createSchemaValidator } from '../../validators/schemaValidator';

import styles from './financial.module.scss';
import { pickFields } from './meta';
import AppFormItem from '../components/AppFormItem';
import { Borrower, Liability, LiabilityName, LoanPurpose } from '../../schema';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

let editingCol: number | null = null;
const schemaValidator = createSchemaValidator(pickFields('liabilities'));

const LiabilityStep: React.FC<Props> = observer(function LiabilityStep() {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const [showAdd, setShowAdd] = useState(false);
  const [modalLiability, setModalLiability] = useState<Partial<Liability>>({});
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const borrower = loan.borrower as Borrower;
  const statement = borrower.financialStatement;
  if (!statement || !statement.liabilities) {
    loanStore.setupLiabilities(borrower);
    return null;
  }

  const handleSubmitLiability = () => {
    loanStore.addLiability(borrower, modalLiability as Liability, editingCol);
    // noinspection JSIgnoredPromiseFromCall
    loanStore.syncDoc();
    setShowAdd(false);
    form.resetFields();
    editingCol = null;
  };

  const handleCancel = () => {
    setShowAdd(false);
    form.resetFields();
    editingCol = null;
    return false;
  };

  const columns: ColumnsType<Liability> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Institution',
      dataIndex: 'institution',
      key: 'institution',
      responsive: ['md'],
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text: any) => formatCurrency(text),
    },
    {
      title: 'Payment',
      dataIndex: 'payment',
      key: 'payment',
      render: (text: any) => (text ? formatCurrency(text) : ''),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text: any, record: Liability, index: number) => (
        <Space size="middle">
          <Button
            onClick={() => {
              editingCol = index;
              form.setFieldsValue(record);
              setModalLiability({});
              setShowAdd(true);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            onClick={() => {
              loanStore.removeLiability(borrower, index);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <LoanLayout className="assetsLayout">
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat ? 'Tell us about any outstanding debts' : 'Liabilities'}
      </h1>
      {loan?.purpose === LoanPurpose.boat && (
        <p>Add any outstanding debts, not including real estate and vehicles (optional).</p>
      )}

      {loan?.purpose !== LoanPurpose.boat && (
        <p className="assetsSubtitle">
          Next please add any outstanding debts, not including real estate and vehicles.
        </p>
      )}

      <Table dataSource={toJS(statement.liabilities)} columns={columns} pagination={false} />

      <Modal title="Add Liability" visible={showAdd} footer={false} onCancel={handleCancel}>
        <Form form={form} {...formItemLayout} onFinish={handleSubmitLiability}>
          <AppFormItem
            name="name"
            required
            rules={[
              schemaValidator('name', modalLiability, {
                message: 'Please enter a type of account or debt',
              }),
            ]}
          >
            <PlaceholderLabel label="Type of liability">
              <Select
                suffixIcon={<SelectArrowIcon />}
                showSearch={Object.keys(LiabilityName).length > 7}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.entries(LiabilityName).map(([key, label]) => (
                  <Select.Option key={key} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </PlaceholderLabel>
          </AppFormItem>

          <AppFormItem
            name="institution"
            required
            rules={[
              schemaValidator('institution', modalLiability, {
                message: 'Please enter a name',
              }),
            ]}
          >
            <PlaceholderLabel label="Institution">
              <Input />
            </PlaceholderLabel>
          </AppFormItem>

          <AppFormItem
            name="balance"
            required
            rules={[
              schemaValidator('balance', modalLiability, {
                message: 'Please enter the balance',
                transform: 'float',
              }),
            ]}
          >
            <PlaceholderLabel label="Balance">
              <MoneyInput />
            </PlaceholderLabel>
          </AppFormItem>

          <AppFormItem
            name="payment"
            required
            rules={[
              schemaValidator('payment', modalLiability, {
                message: 'Please enter the payment',
                transform: 'float',
              }),
            ]}
          >
            <PlaceholderLabel label="Monthly Payment">
              <MoneyInput />
            </PlaceholderLabel>
          </AppFormItem>

          <Row justify="end">
            <Button name="cancel" type="default" htmlType="button" onClick={handleCancel}>
              Cancel
            </Button>

            <Button name="ok" className={styles.addButton} type="primary" htmlType="submit">
              Okay
            </Button>
          </Row>
        </Form>
      </Modal>

      <Row className={styles.tableActionRow}>
        <Button
          name="add"
          type="default"
          htmlType="button"
          onClick={() => {
            setShowAdd(true);
            return false;
          }}
        >
          <PlusOutlined />
          Add Liability
        </Button>
      </Row>

      <ButtonNav onNext={stepFinished} />
      <StepFooter type="secure" />
    </LoanLayout>
  );
});

export default LiabilityStep;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import { Form } from 'antd';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { getStepUrl, requiredSteps, Route, stepFinished } from '../../nav';
import { getAllFieldsFromMeta, getMeta, getValueWithMeta, pickFields, StepMetaType } from './meta';
import {
  LoanPurpose,
  OwnershipType,
  Source,
  SubSource,
  trackingSchema,
  validateLoan,
} from '../../schema';
import { runInAction } from 'mobx';
import { labelize, arrayShallowEquals, formatCurrency, betterParseFloat } from '../../utils';
import { ZodIssue } from 'zod';
import useConfig from '../../stores/config';
import { FF_FULL_APPLICATION_STYLING, RENT_OR_OWN_LABEL_MAP } from '../../constants';

import styles from './ReviewStep.module.scss';
import classNames from 'classnames';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag } from '../../featureFlags';

type SchemaStepProps = {
  meta: StepMetaType;
  step: Route;
  schema: any;
  issues: ZodIssue[];
  sources: Array<Source>;
};

type SchemaRowProps = {
  meta: StepMetaType;
  issues: ZodIssue[];
  rowKey: string;
  value: unknown;
  source: Source | null;
  subSources: SubSource[];
};

const ignoredKeys = ['userAgent', 'specificCollateralKnown'];

const SchemaRow: React.FC<SchemaRowProps> = observer(
  ({ meta, rowKey: key, issues, value, source, subSources }) => {
    const loanStore = useLoan();
    const { loan } = loanStore;
    if (!loan) return null;

    const objectFields = new Set(['utm', 'marketing']);
    const pathToField = meta.path.concat([key]);
    let errorIssue = null;
    for (let i = 0; i < issues.length; i++) {
      const issue = issues[i];
      if (arrayShallowEquals(issue.path, pathToField)) {
        errorIssue = issue;
      }
    }

    if (ignoredKeys.includes(key)) {
      return null;
    }

    if (errorIssue === null && (value === null || value === undefined || value === '')) return null;

    if (value === true) value = 'Yes';
    else if (value === false) value = 'No';
    else if (value === undefined) value = 'missing!';
    else if (key === 'member' && meta.step !== 'marketing' && !loan.showMemberField) return null;
    else if (key.match(/value|balance|amount|payment|owed|price|salesTax|tradein/i))
      value = formatCurrency(betterParseFloat(value));
    else if (
      key.match(/manufacturer|model|loantype|fuel|[a-zA-Z]name|^name|institution|applicantType/i)
    ) {
      value = labelize(String(value));
    } else if (key === 'rentOrOwn') value = RENT_OR_OWN_LABEL_MAP[String(value)];
    else if (key === 'tracking' && value) {
      return (
        <>
          {Object.keys(trackingSchema.shape).map((trackingKey) => (
            <SchemaRow
              meta={meta}
              key={trackingKey}
              rowKey={trackingKey}
              issues={issues}
              value={(value as any)[trackingKey]}
              source={source}
              subSources={subSources}
            />
          ))}
        </>
      );
    } else if (objectFields.has(key) && value && typeof value === 'object') {
      return (
        <>
          {Object.entries(value).map(([fieldKey, fieldValue]) => (
            <SchemaRow
              meta={meta}
              key={fieldKey}
              rowKey={fieldKey}
              issues={issues}
              value={fieldValue}
              source={source}
              subSources={subSources}
            />
          ))}
        </>
      );
    }

    let label = key;
    if (label === 'liveaboard' && loan?.purpose === LoanPurpose.rv) label = 'Fulltimer';
    if (label === 'loa') label = 'Length';
    else if (label === 'ssn') label = 'SSN';
    else if (label === 'id') label = 'Online Session ID';
    else if (label === 'associatedName')
      label = loan?.ownershipType === OwnershipType.trust ? 'Trust Name' : 'LLC Name';
    else if (label === 'source') {
      label = `${source?.name || 'Not found'}`;
    } else if (label === 'subSource') {
      label = 'How did you hear about us';
      for (let i = 0; i < subSources.length; i++) {
        // eslint-disable-next-line eqeqeq
        if (subSources[i].id == value) {
          value = subSources[i].name;
        }
      }
    } else if (label === 'subSourceName') {
      label = '(Other) Description';
    } else if (label.startsWith('utm_')) {
      // don't change
    } else if (label.startsWith('sellerCollateralURL')) {
      label = 'Seller Collateral URL';
    } else label = labelize(label);

    return (
      <div key={key} data-testid={pathToField.join('/')}>
        <p>
          {label}: {String(value)}
        </p>
        {errorIssue && (
          <p className={`${styles.validationError} error`}>Error: {errorIssue.message}</p>
        )}
      </div>
    );
  }
);

const SchemaStep: React.FC<SchemaStepProps> = observer(
  ({ meta, step, schema, issues, sources }: SchemaStepProps) => {
    const loanStore = useLoan();
    const { loan } = loanStore;
    const config = useConfig();
    const activeFeatureFlags = useFeatureFlags();
    const isFeatureFlagOn = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
    const overrideParams = { [FullAppOptimizeV1Flag.name]: isFeatureFlagOn };

    if (!loan) return null;

    const { sharesAddress } = loanStore.getCoBorrower();

    let subSources: SubSource[] = sources[0].sub;
    let source: Source | null = null;
    try {
      if (loan.source !== undefined && config) {
        source = config.getSource(loan.source);
        if (loan.subSource !== undefined) {
          subSources = source.sub;
        }
      }
    } catch (e) {
      console.error(e);
    }

    if (step === 'coborrower-address' && sharesAddress) {
      return (
        <div
          className={classNames('review-step-table', {
            onlyForBoats: loan?.purpose === LoanPurpose.boat,
          })}
        >
          <div className={styles.header}>
            <h4>{meta.label}</h4>
            <Link to={getStepUrl(step)}>Edit</Link>
          </div>
          <div className={styles.values}>
            <div key="sharesAddress" data-testid={meta.path.concat('sharesAddress')}>
              <p>
                {labelize('sharesAddress')}: {String(sharesAddress)}
              </p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames('review-step-table', {
          onlyForBoats: loan?.purpose === LoanPurpose.boat,
        })}
      >
        <div className={styles.header}>
          <h4>{meta.label}</h4>
          <Link to={getStepUrl(step)}>Edit</Link>
        </div>
        <div className={styles.values}>
          {getAllFieldsFromMeta(meta, overrideParams).map((key) => {
            const value = getValueWithMeta({
              meta,
              loan,
              key,
              overrideParams,
            });
            return (
              <SchemaRow
                meta={meta}
                key={key}
                rowKey={key}
                issues={issues}
                value={value}
                source={source}
                subSources={subSources}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

type Props = {
  sources: Array<Source>;
};

const ReviewStep: React.FC<Props> = observer(({ sources }) => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const activeFeatureFlags = useFeatureFlags();
  const isFeatureFlagOn = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });
  const overrideParams = { [FullAppOptimizeV1Flag.name]: isFeatureFlagOn };
  const [issues, setIssues] = useState<ZodIssue[]>([]);

  useEffect(() => {
    if (!loan) return;
    try {
      if (loan.purpose === LoanPurpose.aircraft) {
        loan.liveaboard = false;
      }
      runInAction(() => validateLoan(loan));
      setIssues([]);
    } catch (e: any) {
      setIssues(e.issues);
    }
  }, [loan]);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const update = (_values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  const steps = requiredSteps.get() as Array<Route>;

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">Review</div>}

      <h1>Review your application</h1>

      <Form onFinish={update}>
        {loanStore.userIsAdmin && (
          <SchemaStep
            key="marketing"
            meta={getMeta('marketing')}
            step="marketing"
            schema={pickFields('marketing')}
            issues={issues}
            sources={sources}
          />
        )}
        {steps.map((step) => {
          let meta;
          try {
            meta = getMeta(step);
          } catch (e) {
            return null;
          }
          const schema = pickFields(step);

          if (meta.isArray) {
            const ar = getValueWithMeta({
              meta,
              loan,
              overrideParams,
            });
            if (!ar) return null;
            const rows = [];
            for (let i = 0; i < ar.length; i++) {
              const rowMeta: StepMetaType = {
                ...meta,
                // add the index to the path to find the value
                path: meta.path.concat([i]),
              };
              rows.push(
                <SchemaStep
                  key={`step-${i}`}
                  meta={rowMeta}
                  step={step}
                  schema={schema}
                  issues={issues}
                  sources={sources}
                />
              );
            }
            return rows;
          }

          if (!schema) return null;
          return (
            <SchemaStep
              key={step}
              meta={meta}
              step={step}
              schema={schema}
              issues={issues}
              sources={sources}
            />
          );
        })}
        <ButtonNav />
      </Form>
    </LoanLayout>
  );
});

export default ReviewStep;

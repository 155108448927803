import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import EmployerForm from '../components/EmployerForm';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import { Employer, IncomeSource } from '../../schema';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { AddOnServices } from '../components/AddOnServices';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag } from '../../featureFlags';

type Props = {};

const EmployerStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const activeFeatureFlags = useFeatureFlags();
  const isFeatureFlagOn = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const employer = loanStore.getEmployer();
  const showAdditionalEmployer =
    loan?.borrower?.primaryIncomeSource === IncomeSource.salary &&
    loan?.borrower?.secondaryIncomeSource === IncomeSource.salary;
  const additionalEmployer = showAdditionalEmployer ? loanStore.getEmployer2() : ({} as Employer);

  const update = (values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    loanStore.setValue('addOnServices', values?.loan?.addOnServices);
    stepFinished();
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1>Your Employment Information</h1>
      <Form form={form} onFinish={update}>
        <EmployerForm employer={employer} form={form} />
        {showAdditionalEmployer && (
          <>
            <br />
            <h1>Additional Employment Information</h1>
            <EmployerForm employer={additionalEmployer} form={form} formItemPrefix="additional-" />
          </>
        )}
        {isFeatureFlagOn && <AddOnServices />}
        <ButtonNav />
        <StepFooter type="secure" />
      </Form>
    </LoanLayout>
  );
});

export default EmployerStep;

import React from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { rowLayout } from '../layout';
import TradeInForm from '../components/TradeInForm';
import { PhoneInput } from '../components/PhoneInput';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from './meta';
import AppFormItem from '../components/AppFormItem';
import { LoanPurpose, LoanType, SellerType } from '../../schema';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppStylingFlag } from '../../featureFlags';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const largeThird = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 };
const largeHalf = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };

const schemaValidator = createSchemaValidator(pickFields('seller'));

const SellerStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const activeFeatureFlags = useFeatureFlags();
  const isNewFunnelStyling = !!activeFeatureFlags[FullAppStylingFlag.name];

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const update = () => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  let sellerOptions = [
    { label: 'Dealer / Broker', value: SellerType.dealer },
    { label: 'Undecided', value: SellerType.undecided },
  ];
  if (loan.loanType === LoanType.used) {
    sellerOptions = [
      { label: 'Dealer / Broker', value: SellerType.dealer },
      { label: 'Private Party', value: SellerType.private },
    ];
  } else if (loan.loanType !== LoanType.new) {
    sellerOptions = [
      { label: 'Dealer / Broker', value: SellerType.dealer },
      { label: 'Private Party', value: SellerType.private },
      { label: 'Undecided', value: SellerType.undecided },
    ];
  }

  return (
    <LoanLayout>
      {isNewFunnelStyling && <div className="step-title">Boat info</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat
          ? `Tell us a little about the boat`
          : "Let us know who you're buying from:"}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>Who are you buying from?</p>}
      <Form form={form} {...formItemLayout} initialValues={loan} onFinish={update}>
        {loan.loanType !== LoanType.new && (
          <Col {...largeHalf}>
            <AppFormItem
              name="seller"
              className="manual-label"
              required
              hasFeedback={false}
              rules={[
                { required: true, message: 'Please select one' },
                schemaValidator('seller', loan),
              ]}
              wrapperCol={{ span: 24, offset: 0 }}
            >
              <ManualLabel label="Who are you buying from?" twoLine>
                <Radio.Group
                  optionType="button"
                  disabled={loan.loanType === LoanType.refinance}
                  options={sellerOptions}
                />
              </ManualLabel>
            </AppFormItem>
          </Col>
        )}

        {loan.seller && loan.seller !== SellerType.undecided && loan.seller !== SellerType.dealer && (
          <Row {...rowLayout}>
            <div className="mobileSpacing" />

            <Col {...largeThird}>
              <AppFormItem
                name="sellerName"
                rules={[schemaValidator('sellerName', loan)]}
                hasFeedback
              >
                <PlaceholderLabel label="Seller's Name" htmlFor="sellerName">
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>

            <div className="mobileSpacing" />

            <Col {...largeThird}>
              <AppFormItem name="sellerPhone" rules={[schemaValidator('sellerPhone', loan)]}>
                <PlaceholderLabel label="Seller's Phone" htmlFor="sellerPhone">
                  <PhoneInput placeholder={isNewFunnelStyling ? 'Enter Phone' : undefined} />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>

            <div className="mobileSpacing" />

            <Col {...largeThird}>
              <AppFormItem name="sellerEmail" rules={[schemaValidator('sellerEmail', loan)]}>
                <PlaceholderLabel label="Seller's Email" htmlFor="sellerEmail">
                  <Input inputMode="email" />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>
          </Row>
        )}

        {loan.seller && loan.seller === SellerType.dealer && (
          <>
            <Row {...rowLayout}>
              <div className="mobileSpacing" />

              <Col {...largeHalf}>
                <AppFormItem
                  name="sellerName"
                  rules={[schemaValidator('sellerName', loan)]}
                  hasFeedback
                >
                  <PlaceholderLabel label="Dealer/Broker Name (optional)" htmlFor="sellerName">
                    <Input />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>

              <div className="mobileSpacing" />

              <Col {...largeHalf}>
                <AppFormItem
                  name="sellerRepName"
                  rules={[schemaValidator('sellerRepName', loan)]}
                  hasFeedback
                >
                  <PlaceholderLabel label="Sales Rep Name (optional)" htmlFor="sellerRepName">
                    <Input />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </Row>
          </>
        )}

        {loan.seller === SellerType.dealer && (
          <Row>
            <AppFormItem
              className="manual-label"
              name="hasTrade"
              required
              rules={[
                { required: true, message: 'Please select one' },
                schemaValidator('hasTrade', loan),
              ]}
              wrapperCol={{ span: 24, offset: 0 }}
              hasFeedback={false}
            >
              <ManualLabel label="Have Trade In?" twoLine>
                <Radio.Group
                  optionType="button"
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
              </ManualLabel>
            </AppFormItem>
          </Row>
        )}

        {loan?.hasTrade && (
          <Row>
            <TradeInForm />
          </Row>
        )}

        <AppFormItem
          required={false}
          name="sellerCollateralURL"
          rules={[schemaValidator('sellerCollateralURL', loan)]}
          hasFeedback
        >
          <PlaceholderLabel
            label="Seller's collateral listing website URL (optional)"
            htmlFor="sellerCollateralURL"
          >
            <Input />
          </PlaceholderLabel>
        </AppFormItem>

        <ButtonNav />
        <StepFooter type="info" />
      </Form>
    </LoanLayout>
  );
});

export default SellerStep;

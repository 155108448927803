import React from 'react';
import { Form } from 'antd';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import IncomeForm from '../components/IncomeForm';
import { numSalaryIncomeSources } from '../../utils';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { LoanPurpose } from '../../schema';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../../constants';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const IncomeStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const borrower = loanStore.getBorrower();

  const update = (values: any) => {
    if (numSalaryIncomeSources(values) < 2 && loan?.borrower?.currentEmployer2) {
      // Make the values null if there is no longer a secondary salary
      runInAction(() => {
        if (loan.borrower) {
          loan.borrower.currentEmployer2 = null;
        }
      });
    }

    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat
          ? 'Tell us a little about you'
          : 'We need a few financial details'}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>Add your financial information</p>}
      <Form form={form} {...formItemLayout} initialValues={borrower || {}} onFinish={update}>
        <IncomeForm borrower={borrower} />

        <ButtonNav />
        <StepFooter type="secure" />
      </Form>
    </LoanLayout>
  );
});

export default IncomeStep;
